import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./FleetOverviewRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import KPIRoutes from "./PerformanceRoutes";
import AdminRoutes from "./AdminRoutes";
import OperationalLogsRoutes from "./OperationalLogsRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    LoginRoutes,
    AuthenticationRoutes,
    KPIRoutes,
    AdminRoutes,
    OperationalLogsRoutes,
  ]);
}
