import PropTypes from 'prop-types';

const useSupport = () => {
  const createServiceRequest = async ({ axiosInstance, apiUrl }, data) => {
    try {
      const res = await axiosInstance.post(apiUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res?.data?.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error);
      return false;
    }
  };

  return { createServiceRequest };
};

useSupport.propTypes = {
  data: PropTypes.object,
};

export default useSupport;
