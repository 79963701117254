import useSelectedVesselStore from '../store/vesselStore'
import Loader from 'ui-component/Loader'
import React from 'react'

const LoaderforVesseldata = (props) => {
  const loading = useSelectedVesselStore((state) => state.loading)
  const Error = useSelectedVesselStore((state) => state.error)

  if (Error) {
    window.setTimeout(() => {
      return 'Serious issue occured.Failed to Load Data..'
    }, 10000)
  }

  if (loading) {
    return <Loader />
  }
  return props.children
}

export default LoaderforVesseldata
