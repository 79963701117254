import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Input, CardHeader, Divider, Drawer, FormControl, FormHelperText, FormLabel, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useSupportContext } from '../contexts/SupportProvider';
import { LoadingButton } from '@mui/lab';
import CaptureScreenshot from './CaptureScreenshot';
import ScreenshotEditModal from './ScreenshotEditModal';
import useSupport from '../hooks/useSupport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { ServiceRequestSchema } from 'SSPL_Support/schema';
import { useFieldArray, useForm } from 'react-hook-form';
import { faSave } from '@fortawesome/pro-light-svg-icons';

const SupportDrawer = ({ userEmail, appName, axiosInstance, apiUrl }) => {
  const { isSupportPageOpen, toggleSupport } = useSupportContext();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [createServiceRequestError, setcreateServiceRequestError] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isNewServiceRequest, setisNewServiceRequest] = React.useState(true);
  const [screenshot, setScreenshot] = React.useState('');
  const [message, setMessage] = React.useState();

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ServiceRequestSchema),
    defaultValues: {
      attachments: [{ file: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
  });

  const { createServiceRequest } = useSupport();
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleSupportClose = (e, reason) => {
    if (!reason) {
      resetForm();
      setError(null);
      toggleSupport(!isSupportPageOpen);
    }
  };

  const resetForm = () => {
    reset();
    setScreenshot('');
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    var formData = new FormData();
    for (const key in data) {
      if (key != 'attachments') {
        formData.append(key, data[key]);
      }
    }
    formData.append('pageUrl', window.location.href);
    formData.append('pageTitle', document.title);
    formData.append('applicationName', appName);
    formData.append('userEmail', userEmail);
    formData.append('screenshot', screenshot);
    data.attachments.forEach((fileObj, index) => {
      if (fileObj.file && fileObj.file[0]) {
        formData.append(`attachments`, fileObj.file[0]);
      }
    });
    const res = await createServiceRequest({ axiosInstance, apiUrl }, formData);
    if (res) {
      setisNewServiceRequest(false);
      resetForm();
      setMessage('');
    } else {
      setcreateServiceRequestError(true);
      const message = 'Error in raising request. Please try again later or contact administrator.';
      setMessage(message);
    }
    setIsSubmitting(false);
    return false;
  };

  const handleClose = () => {
    resetForm();
    setMessage('');
    toggleSupport(false);
  };

  const handleNewServiceRequestClick = () => {
    setisNewServiceRequest(true);
  };
  return (
    <Drawer data-html2canvas-ignore="true" open={isSupportPageOpen} anchor="right" onClose={handleSupportClose}>
      <Card sx={{ height: '100%', minWidth: '400px', maxWidth: '400px' }}>
        <CardHeader
          sx={{ my: 0, py: 1 }}
          title="Create Support Request"
          action={
            <Stack direciton="row" justifyContent="flext-end" alignItems="center">
              <IconButton onClick={handleClose} color="error">
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Stack>
          }
        />
        <Divider />
        <CardContent sx={{ height: '95%', overflowY: 'scroll' }}>
          {isNewServiceRequest ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={1}>
                <FormControl fullWidth error={errors?.title}>
                  <FormLabel id="title">
                    Title
                    <Typography color="error" display="inline">
                      *
                    </Typography>
                  </FormLabel>
                  <TextField error={errors?.title} variant="outlined" size="small" name="title" {...register('title')} />
                  <FormHelperText error={errors.title}>{errors?.title?.message}</FormHelperText>
                </FormControl>
                <FormControl fullWidth error={errors?.type}>
                  <FormLabel id="demo-simple-select-label">
                    Type
                    <Typography color="error" display="inline">
                      *
                    </Typography>
                  </FormLabel>
                  <Select error={errors.type} labelId="demo-simple-select-label" id="type" size="small" {...register('type')}>
                    <MenuItem value="technical_support">Technical support</MenuItem>
                    <MenuItem value="report_a_bug">Report a bug</MenuItem>
                    <MenuItem value="suggest_improvement">Suggest improvement</MenuItem>
                    <MenuItem value="suggest_a_new_feature">Suggest a new feature</MenuItem>
                    <MenuItem value="licensing_and_billing_questions">Licensing and billing questions</MenuItem>
                    <MenuItem value="other_queries">Other Queries & service request</MenuItem>
                    {/* <MenuItem value="product_trial_questions">Product trial questions</MenuItem> */}
                  </Select>
                  <FormHelperText error={errors.type}>{errors?.type?.message}</FormHelperText>
                </FormControl>
                <FormControl fullWidth error={errors?.description}>
                  <FormLabel id="title">
                    Description
                    <Typography color="error" display="inline">
                      *
                    </Typography>
                  </FormLabel>
                  <TextField variant="outlined" error={errors?.description} size="small" name="description" multiline rows={8} {...register('description')} />
                  <FormHelperText error={errors.description}>{errors?.description?.message}</FormHelperText>
                </FormControl>
                <FormControl fullWidth error={errors?.impact}>
                  <FormLabel id="demo-simple-select-label">Impact</FormLabel>
                  <Select error={errors.impact} labelId="demo-simple-select-label" id="impact" size="small" {...register('impact')}>
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                  <FormHelperText error={errors.impact}>{errors?.impact?.message}</FormHelperText>
                </FormControl>
                <CaptureScreenshot screenshot={screenshot} setScreenshot={setScreenshot} isSupportPageOpen={isSupportPageOpen} setEditModalOpen={setEditModalOpen} />
                <Box mt={1} mb={1}>
                  <Typography mb={1}>Upload files</Typography>
                  {fields.map((item, index) => (
                    <Box key={item.id} my={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Input type="file" name="attachments" {...register(`attachments.${index}.file`)} />
                        <Button type="button" variant="contained" color="warning" size="small" onClick={() => remove(index)}>
                          Remove
                        </Button>
                      </Stack>
                      <FormHelperText error={errors?.attachments?.[index]?.file}>{errors?.attachments?.[index]?.file?.message}</FormHelperText>
                    </Box>
                  ))}
                  <Button mt={1} type="button" variant="contained" color="secondary" size="small" onClick={() => append({ file: '' })}>
                    {fields.length > 0 ? 'Add another attachment' : 'Add attachment'}
                  </Button>
                </Box>
                <Box sx={{ pt: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      variant="contained"
                      size="small"
                      color="primary"
                      loadingPosition="start"
                      startIcon={<FontAwesomeIcon icon={faSave} />}
                      onClick={handleSubmit}
                      disabled={isSubmitting}>
                      Submit
                    </LoadingButton>
                    {/* <Button type="submit" variant="contained" color="primary" size="small" onClick={handleSubmit} disabled={isSubmitting}>
                      Submit
                    </Button> */}
                    <Button variant="contained" color="warning" size="small" type="submit" onClick={handleClose} disabled={isSubmitting}>
                      Cancel
                    </Button>
                  </Stack>
                </Box>
                {error && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ mt: 1 }}>
                  {message && createServiceRequestError && (
                    <Typography variant="body2" color={createServiceRequestError ? 'error' : 'success'}>
                      {message}
                    </Typography>
                  )}
                </Box>
              </Stack>
              <ScreenshotEditModal screenshot={screenshot} setScreenshot={setScreenshot} editModalOpen={editModalOpen} closeEditModal={handleEditModalClose} />
            </form>
          ) : (
            <Stack direction="column" spacing={1}>
              <Box sx={{ mt: 1 }}>
                <Typography fontWeight="bold" color="success">
                  Support request created successfully!.
                </Typography>
              </Box>
              <Box>
                <Button variant="contained" color="primary" onClick={handleNewServiceRequestClick}>
                  Create New Support Request
                </Button>
              </Box>
            </Stack>
          )}
        </CardContent>
      </Card>
    </Drawer>
  );
};

SupportDrawer.propTypes = {
  userEmail: PropTypes.string,
  appName: PropTypes.string,
  axiosInstance: PropTypes.func,
  apiUrl: PropTypes.string,
};

export default SupportDrawer;
