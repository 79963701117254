import { create } from 'zustand'

const useMapViewStore = create((set) => ({
  defaultView: 'MapView',
  update: (args) =>
    set({
      defaultView: args,
    }),
}))

export default useMapViewStore
