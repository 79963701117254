import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Typography, Button, useMediaQuery, useTheme, Box } from '@mui/material';
import { ExitToApp, Home } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Error404 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const pageAccess = JSON.parse(localStorage.getItem('auth'));
  var isUrlPresent = pageAccess?.accessClaims.some((x) => x.url == '/');
  return (
    <Box className={classes.root}>
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        404: The page you are looking for isn’t here
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/"
          onClick={() => {
            localStorage.removeItem('auth');
          }}
          variant="outlined">
          Back to Sign In
        </Button>
        {isUrlPresent && (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Typography sx={{ mt: 1 }}>OR</Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button color="primary" component={RouterLink} to="/" variant="outlined">
              Back to Home
            </Button>
          </>
        )}
      </div>
    </Box>
  );
};
export default Error404;
