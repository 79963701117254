import React, { useState } from 'react'
import { Card, CardContent, CardHeader, Divider, Tooltip, Box, IconButton, useMediaQuery, Skeleton } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import InfoIcon from '@mui/icons-material/Info'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  header: {
    minHeight: 34,
    padding: theme.spacing(1, 1, 0, 1),
  },
  content: {
    margin: 0,
    padding: theme.spacing(1, 0.5, 0, 0),
  },
}))

const getChartTitle = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        fontSize: { xs: '0.7rem', md: '0.9rem', lg: '0.9rem' },
        fontWeight: 200,
      }}>
      <Skeleton />
    </Box>
  )
}
const ChartWrapperSkeleton = ({ height = 400, action }) => {
  const classes = useStyles()

  return (
    <Card elevation={2} className={classes.root}>
      {<CardHeader className={classes.header} action={action} title={getChartTitle()} />}
      {<Divider />}
      <CardContent style={{ paddingBottom: 0 }} className={classes.content}>
        <div style={{ height: height, width: '100%' }}>
          <Skeleton height={height - 10} sx={{ mx: 0.75, mb: 0.75, transform: 'none' }} />
        </div>
      </CardContent>
    </Card>
  )
}

export default ChartWrapperSkeleton
