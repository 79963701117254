import React from 'react';
import SupportDrawer from '../components/SupportDrawer';

function SupportPage(props) {
  return <SupportDrawer {...props} />;
}

SupportPage.propTypes = {};

export default SupportPage;
