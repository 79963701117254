import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Tooltip, Box, IconButton, useMediaQuery, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
// import InfoIcon from '@mui/icons-material/Info'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
// import { IconInfoCircle } from '@tabler/icons'
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  header: {
    minHeight: 34,
    padding: theme.spacing(1, 1, 0, 1),
  },
  content: {
    margin: 0,
    padding: theme.spacing(1, 0.5, 0, 0),
  },
}));

const getChartTitle = (title, titleInfo) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        fontSize: { xs: '0.7rem', md: '0.9rem', lg: '0.9rem' },
        fontWeight: 200,
      }}>
      <Typography variant="body1">
        {title}
        {titleInfo ? (
          <Tooltip
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: matchesXs ? [0, -10] : [0, 0],
                  },
                },
              ],
            }}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
            title={<p style={{ padding: 0, margin: 0 }}>{titleInfo}</p>}>
            <IconButton disableRipple sx={{ p: 0 }} onClick={() => setShowTooltip(!showTooltip)}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{
                  height: '15px',
                  width: '15px',
                  marginTop: '-2px',
                  marginBottom: '16px',
                  marginLeft: '4px',
                  // color: '#87CEFA',
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Typography>
    </Box>
  );
};
const ChartWrapper = ({ height = 400, action, children, title, hasData = true, titleInfo = '' }) => {
  const classes = useStyles();

  return (
    <Card elevation={4} className={classes.root}>
      {title ? <CardHeader className={classes.header} action={action} title={getChartTitle(title, titleInfo)} /> : ''}
      {title ? <Divider /> : ''}
      <CardContent style={{ paddingBottom: 0 }} className={classes.content}>
        <div style={{ height: height, width: '100%' }}>{hasData ? children : 'No Data available'}</div>
      </CardContent>
    </Card>
  );
};

export default ChartWrapper;
