import { create } from "zustand";

const useAlertStore = create((set) => ({
  open: false,
  message: "",
  setAlertOpen: (args) => {
    set({ open: args });
  },

  setAlertMessage: (args) => {
    set({ message: args });
  },

  setAlert: ({ open, message }) => {
    set({ open: open, message: message });
  },
}));

export default useAlertStore;
