import * as yup from 'yup';

const fileSchema = yup
  .mixed()
  .test('fileSize', 'File size should not exceed 5MB', (value) => {
    if (!value || value.length === 0) return true;
    return value && value[0] && value[0].size <= 5 * 1024 * 1024;
  })
  .test('fileType', 'File type should be JPEG, PNG, PDF, DOC, DOCX, XLS, or XLSX', (value) => {
    if (!value || value.length === 0) return true;
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    ];
    return value && value[0] && allowedTypes.includes(value[0].type);
  });

const ServiceRequestSchema = yup.object({
  title: yup.string().required('Title is required.'),
  type: yup.string().required('Request type is required.'),
  description: yup.string().required('Description is required'),
  impact: yup.string().nullable(),
  screenshot: yup.string().nullable(),
  attachments: yup
    .array()
    .of(
      yup.object().shape({
        file: fileSchema.nullable(),
      })
    )
    .nullable(),
});

export default ServiceRequestSchema;
