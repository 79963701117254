import React, { useState } from 'react';
import { Typography, Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash, faFloppyDiskPen, faXmark, faCopy } from '@fortawesome/pro-solid-svg-icons';
import ConfirmDialog from './ConfirmDialog';

const HeaderWithCopyTo = ({ pageRecord, name, Operations, toggleOperation, onAdd, onDelete, onUpdate, message, setMessage, isRegister, state, selectedRowData, onCopyToClick }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isConfirmCancel, setIsConfirmCancel] = useState(false);
  const [mode, setMode] = useState(false);

  const handleClickOpen = (mode, message) => {
    setMode(mode);

    setMessage(message);

    setOpen(true);
  };

  const handleAdd = () => {
    onAdd();
  };

  const handleDelete = () => {
    //setDeleteConfirmationOpen(true)

    // setMode('ERROR')

    // setMessage('Unable to delete as record is used other places.')

    setOpen(true);

    onDelete();

    setOpen(false);
  };
  const handleClose = () => {};

  const handleUpdate = () => {
    onUpdate();
  };

  return (
    <>
      <Grid sx={{ pt: 1, pl: 1.3, pr: 1, pb: 0.25 }} container justify="space-between" spacing={1.5} alignItems="center">
        <Grid container item xl={4} lg={4} md={4} xs={12} sx={{ mt: 0.75, justifyContent: { xs: 'center', md: 'flex-start', lg: 'flex-start' } }}>
          <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }} component="h3" variant="h3">
            {name}
          </Typography>
        </Grid>

        <Grid container item md={8} xs={12} xl={8} lg={8} sx={{ justifyContent: { xs: 'center', md: 'flex-end', lg: 'flex-end' } }}>
          <div>
            {Operations.add ? (
              <div>
                <Button
                  startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faFloppyDiskPen} />}
                  variant="contained"
                  size="small"
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    handleAdd, handleClickOpen('SAVE');
                  }}
                  sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faXmark} />}
                  variant="contained"
                  sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    toggleOperation('add');
                  }}>
                  Cancel
                </Button>
              </div>
            ) : Operations.update ? (
              <div>
                <Button
                  startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faFloppyDiskPen} />}
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    handleUpdate, handleClickOpen('SAVE');
                  }}
                  sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faXmark} />}
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => toggleOperation('update')}
                  sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                  Cancel
                </Button>
              </div>
            ) : state ? (
              <div>
                {!isRegister ? (
                  // &nbsp;&nbsp;&nbsp;
                  <>
                    {pageRecord?.isAddOption && (
                      <Button
                        startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faPlus} />}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => toggleOperation('add')}
                        sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                        Add
                      </Button>
                    )}
                  </>
                ) : (
                  // &nbsp;&nbsp;&nbsp;

                  <>
                    {pageRecord?.isAmendOption && (
                      <Button
                        startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faPen} />}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => toggleOperation('update')}
                        sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                        Amend
                      </Button>
                    )}
                    &nbsp;&nbsp;&nbsp;
                    {pageRecord?.isDeleteOption && (
                      <Button
                        startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faTrash} />}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          if (!selectedRowData) {
                            handleDelete();
                          } else {
                            handleClickOpen('DELETE');
                          }
                        }}
                        sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                        Delete
                      </Button>
                    )}
                    &nbsp;&nbsp;&nbsp;
                    {pageRecord?.isAmendOption && (
                      <Button
                        startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faCopy} />}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          onCopyToClick();
                        }}
                        sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                        Copy To
                      </Button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div>
                &nbsp;&nbsp;&nbsp;
                {pageRecord?.isAddOption && (
                  <Button
                    startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faPlus} />}
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => toggleOperation('add')}
                    sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                    Add
                  </Button>
                )}
                &nbsp;&nbsp;&nbsp;
                {pageRecord?.isAmendOption && (
                  <Button
                    startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faPen} />}
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => toggleOperation('update')}
                    sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                    Amend
                  </Button>
                )}
                &nbsp;&nbsp;&nbsp;
                {pageRecord?.isDeleteOption && (
                  <Button
                    startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faTrash} />}
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      // handleDelete, handleClickOpen('DELETE')
                      if (!selectedRowData) {
                        handleDelete();
                      } else {
                        handleClickOpen('DELETE');
                      }
                    }}
                    sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
                    Delete
                  </Button>
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <Box>
        <ConfirmDialog
          type={mode}
          open={open}
          setOpen={setOpen}
          setIsConfirm={setIsConfirmCancel}
          onClose={mode === 'DELETE' ? handleClose : ''}
          onConfirm={mode === 'DELETE' ? handleDelete : mode === 'SAVE' ? handleAdd : ''}
          message={message}
        />
      </Box>
    </>
  );
};

export default HeaderWithCopyTo;
