// routing
import Routes from 'routes';

// project imports

import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { Helmet } from 'react-helmet';
import 'assets/scss/kendo-light.scss';
import 'assets/scss/kendo-dark.scss';
import useConfig from 'hooks/useConfig';
import Locales from 'ui-component/Locales';
// auth provider

import { LicenseInfo } from '@mui/x-license-pro';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { SupportProvider } from 'SSPL_Support/contexts/SupportProvider';

LicenseInfo.setLicenseKey('10ca57a75dd3d7c494d9617fedb0612fTz01MzY1MCxFPTE2OTkwOTcxOTYwMzAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

// ==============================|| APP ||============================== //

const App = () => {
  const { navType } = useConfig();
  return (
    <ThemeCustomization>
      <Locales>
      <SupportProvider>
        <Helmet>
          <body className={`kendo-${navType == 'dark' ? 'dark' : 'light'}-theme`}></body>
        </Helmet>
        <Routes />
        <Snackbar />
        </SupportProvider>
      </Locales>
    </ThemeCustomization>
  );
};
export default App;
