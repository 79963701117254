// material-ui
import { useTheme } from '@mui/styles';
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, Typography, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { authService } from '../../../services/auth-service';
import useAlertStore from 'store/alertStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import useMapViewStore from 'store/MapViewStore';

import { fetchJSON } from 'utils/FetchData';

const ChangePasswordPopup = ({ changePasswordFields, setChangePasswordFields }) => {
  const theme = useTheme();
  let user = JSON.parse(localStorage.getItem('auth'));
  let firstName = user?.user?.firstName ?? 'No';
  let lastName = user?.user?.lastName ?? 'Name';
  let flName = Array.from(firstName)[0] + Array.from(lastName)[0];
  const avatarRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [error, setError] = useState('');
  const [samePasswordErr, setSamePasswordErr] = useState('');
  const handleAvatarClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLogout = () => {
    // Add your logout logic here
    authService.logout(); // Example logout function
  };

  const handleChangeNewPassword = () => {
    setChangePasswordFields(true);
  };
  const handleCancelChangePassword = () => {
    setNewPassword('');
    setConfirmPassword('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setSamePasswordErr();
    setError();
    setChangePasswordFields(false);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const valid = avatarRef.current.reportValidity();

    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,30}$/;
    var isValidNewPassword = passwordRegex.test(newPassword);
    var isValidConfirmPassword = newPassword === confirmPassword;

    if (!isValidNewPassword) {
      // setNewPasswordError('Passwords must be at least 8 char, one uppercase (A-Z), one lowercase (a-z), one number (0-9)');
      setNewPasswordError('Entered Password is not in Password Format');
      setSamePasswordErr();
      setError();
    } else {
      setNewPasswordError('');
    }

    if (!isValidConfirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      setSamePasswordErr();
      setError();
    } else {
      setConfirmPasswordError('');
    }

    if (valid && isValidNewPassword && isValidConfirmPassword) {
      // Passwords are valid, proceed with the logic
      const body = {
        id: user?.user?.userId,
        newPassword: confirmPassword,
      };

      const { data, error, isLoading } = await fetchJSON(`${window.Constants.BaseUrl}/UserAuthentication/changePassword`, 'POST', body);
      const errorStatus = JSON.parse(error)?.status;
      isValidNewPassword = false;
      isValidConfirmPassword = false;

      if (data && data?.status === 1) {
        authService.logout();
        setSamePasswordErr();
        setError();
      }
      if (!data && errorStatus === 400) {
        setSamePasswordErr('Error');
      }
      if (!data && errorStatus === 404) {
        setError('Error Occured');
      }
    }
  };
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Avatar
        sx={{
          width: 70,
          height: 70,
          fontSize: '2rem',
          background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
          color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
          margin: 'auto',
        }}
        onClick={handleAvatarClick}>
        {flName}
      </Avatar>
      <Typography sx={{ marginTop: 1, fontSize: '16.6px', fontWeight: 500 }}>
        Hi, {firstName} {lastName}!
      </Typography>
      <Box sx={{ display: 'flex', pt: '8px' }}>
        <Button
          id="logoutButton"
          variant="outlined"
          color="secondary"
          sx={{
            width: 'calc(50%)',
            borderTopLeftRadius: '25px',
            borderBottomLeftRadius: '25px',
            mr: '0px',
          }}
          startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faArrowRightFromBracket} />}
          onClick={handleLogout}>
          Logout
        </Button>
        <Button
          id="changePasswordButton"
          variant="outlined"
          color="secondary"
          sx={{
            width: 'calc(50%)',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
          }}
          startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faLock} />}
          onClick={handleChangeNewPassword}>
          Change Password
        </Button>
      </Box>
      {changePasswordFields && (
        <form ref={avatarRef} onSubmit={handleSubmit}>
          <>
            <Box sx={{ pt: '12px' }}>
              <Grid item md={12} container justifyContent={'right'} alignItems={'right'}>
                <Grid item md={12} sx={{ pb: '12px' }}>
                  <TextField
                    sx={{ color: 'white' }}
                    required={true}
                    type={showNewPassword ? 'text' : 'password'}
                    variant="filled"
                    fullWidth
                    size="small"
                    label="New Password"
                    name="newPassword"
                    // placeholder="New Password*"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{}} aria-label="toggle password visibility" onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownNewPassword} edge="end" size="large">
                            {showNewPassword ? (
                              <Visibility sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                            ) : (
                              <VisibilityOff sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ textAlign: 'left' }}>
                    {newPasswordError && (
                      <Typography variant="body1" color={theme.palette.error.main}>
                        {''}
                        Passwords must be at least 8 char, one uppercase<br></br>
                        (A-Z), one lowercase (a-z), one number (0-9).
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid item md={12} container justifyContent={'right'} alignItems={'right'}>
                <Grid item md={12} sx={{ pb: '12px' }}>
                  <TextField
                    required={true}
                    type={showConfirmPassword ? 'text' : 'password'}
                    variant="filled"
                    fullWidth
                    size="small"
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    // placeholder="Confirm New Password*"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{}} aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownConfirmPassword} edge="end" size="large">
                            {showConfirmPassword ? (
                              <Visibility sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                            ) : (
                              <VisibilityOff sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ textAlign: 'left' }}>
                    {confirmPasswordError && (
                      <Typography variant="body1" color={theme.palette.error.main}>
                        {''}
                        Entered Passwords do not match.
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', pb: '12px' }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleCancelChangePassword}
                  sx={{
                    mr: '8px',
                  }}>
                  Cancel
                </Button>
                <Button fullWidth variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box>
              <Box>
                {error && (
                  <Typography variant="body1" align="left" color={theme.palette.error.main}>
                    Error Occured, please contact administrator!
                  </Typography>
                )}
                {samePasswordErr && (
                  <Typography variant="body1" align="left" color={theme.palette.error.main}>
                    New password cannot be the same as the old<br></br>
                    password!
                  </Typography>
                )}
                <Typography variant="h6" color="textSecondary" align="left" sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                  Once the password is changed, you will be redirected to <br></br>the login page.
                </Typography>
              </Box>
            </Box>
          </>
        </form>
      )}
    </Box>
  );
};

export default ChangePasswordPopup;
