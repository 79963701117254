import { Box, Card, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { faBell, faShip, faCopy, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import CopyToVesselGrid from './CopyToVesselGrid';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

export default function CopyTo({ selectedVessel, openCopyTo, handleCloseCopyTo, setSuccessAlert, CopyToVesselData, onRowClick, Operations, Disabled, handleCopyTo }) {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      title={
        <div>
          <Typography variant="h3" sx={{ paddingRight: '16px' }}>
            Copy Equipment Utilization Configuration
          </Typography>
        </div>
      }
      open={openCopyTo}
      onClose={handleCloseCopyTo}
      width={matchesXs ? '100%' : '50%'}
      height={625}
      style={{ fontWeight: 500, zIndex: 2, marginTop: 25 }}
      sx={{
        '& .MuiPaper-root': {
          padding: 0,
          maxWidth: '50%',
        },
        '& .k-dialog .k-i-close': {
          color: 'red',
        },
      }}>
      <Grid container item md={12}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography display="inline-block" variant="h4">
              {selectedVessel && <FontAwesomeIcon icon={faShip} style={{ fontSize: '1rem', marginRight: '10px' }} />} {selectedVessel.name}, {selectedVessel.imo}, {selectedVessel.vesselType}
            </Typography>
          </Grid>
          <Grid container item sm={12} md={12} py={'10px'}>
            <Card elevation={2}>
              <Typography variant="h4" style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '12px', position: 'relative' }}>
                Copy To Vessel(s)
              </Typography>
              <CopyToVesselGrid CopyToVesselData={CopyToVesselData} disabled={Disabled} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <DialogActionsBar>
        <Grid container justifyContent="flex-end">
          <Button
            startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faCopy} />}
            variant="contained"
            size="small"
            color="primary"
            sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '', marginRight: '8px' }}
            onClick={handleCopyTo}>
            Copy To
          </Button>
          <Button
            startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faXmark} />}
            variant="outlined"
            size="small"
            color="primary"
            sx={{ color: theme.palette.mode === 'light' ? 'primary' : '' }}
            onClick={handleCloseCopyTo}>
            Cancel
          </Button>
        </Grid>
      </DialogActionsBar>
    </Dialog>
  );
}
