const BASE_URL = `${window.Constants.IdentitySericeUrl}/UserAuthentication`;

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${BASE_URL}/register`, requestOptions).then(handleResponse);
}

function login(email, password, appBaseCode) {
  var CredentialData = JSON.stringify({ email, password, appBaseCode });
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: CredentialData,
  };

  return fetch(`${BASE_URL}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('auth', JSON.stringify(user));
      //history.push("/");
      return Promise.resolve(user);
    });
}

const logout = () => {
  localStorage.removeItem('auth');
  window.location.reload();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

const getAccess = (url) => {
  const pageAccess = localStorage.getItem('auth');
  const fData = JSON.parse(pageAccess);
  const pageData = fData?.accessClaims;
  const pageRecord = pageData?.filter((x) => x.url == url)[0];
  return pageRecord;
};
export const authService = {
  register,
  login,
  logout,
  getCurrentUser,
  getAccess,
};
