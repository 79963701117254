import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import useSelectedVesselStore from '../store/vesselStore';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, Grid, Popper, Autocomplete, TextField, CircularProgress, createFilterOptions } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import { getIntlString } from 'utils/helpers';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 1px',
  },
}));

const OutlineInputStyle = styled(Autocomplete, { shouldForwardProp })(({ theme }) => ({
  width: 265,
  paddingLeft: '2.5em',
  paddingTop: '3.5px',
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingTop: 0,
    width: '100%',
    marginLeft: 4,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
  },
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
  },
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ open, openDropdown, closeDropdown, loading, defaultVessel, handleChangeLanding, vesselList, popupState }) => {
  const theme = useTheme();

  return (
    <div style={{ display: 'flex', Width: 'max-content' }}>
      <OutlineInputStyle
        multiple={false}
        id="free-solo-2-demo"
        autoSelect={false}
        open={open}
        onOpen={openDropdown}
        onClose={closeDropdown}
        isOptionEqualToValue={(option, value) => option.vesselName === value.vesselName}
        getOptionLabel={(option) => option.vesselName || ''}
        // filterOptions={filterOptions}
        options={vesselList}
        loading={loading}
        defaultValue={defaultVessel}
        selectOnFocus={false}
        onChange={(e, value) => handleChangeLanding(value)}
        renderOption={(props, option, { selected }) => <li {...props}>{option.vesselName}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            // style={{ height: -1 }}
            placeholder={getIntlString('Search Vessel')}
            //variant="standard"
            size="small"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/* <SearchIcon /> */}
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />

      <Box sx={{ ml: 2 }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
            color: theme.palette.orange.dark,
            '&:hover': {
              background: theme.palette.orange.dark,
              color: theme.palette.orange.light,
            },
          }}
          {...bindToggle(popupState)}>
          <IconX stroke={1.5} size="1.3rem" />
        </Avatar>
      </Box>
    </div>
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //
const filterOptions = createFilterOptions({
  stringify: (option) => option.vesselName + option.imo,
});
const AutocompleteLanding = ({ handleChangeLanding, defaultVessel }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const vesselList = useSelectedVesselStore((state) => state.vesselList);
  const loading = open && vesselList.length === 0;

  const closeDropdown = useCallback(() => setOpen(false), []);
  const openDropdown = useCallback(() => setOpen(true), []);

  return (
    <>
      <Box sx={{ display: { sm: 'block', md: 'none' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {/* sx={{  display: { md: 'none' } }} */}
          {(popupState) => (
            <>
              <Box sx={{ sm: 'content', ml: 2, width: ' 95px' }}>
                <HeaderAvatarStyle variant="circular" {...bindToggle(popupState)}>
                  <IconSearch stroke={1.5} size="1.2rem" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                      <Card
                        sx={{
                          background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                          [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: 'none',
                          },
                        }}>
                        <Box sx={{ px: 0.875, pt: 2.5, pb: 0.5 }}>
                          <Grid container alignItems="center" justifyContent="center">
                            <Grid item xs>
                              <MobileSearch
                                open={open}
                                openDropdown={openDropdown}
                                closeDropdown={closeDropdown}
                                loading={loading}
                                //  defaultVessel={defaultVessel}
                                vesselList={vesselList}
                                handleChangeLanding={handleChangeLanding}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <OutlineInputStyle
          multiple={false}
          id="free-solo-2-demo"
          autoSelect={false}
          open={open}
          onOpen={openDropdown}
          onClose={closeDropdown}
          isOptionEqualToValue={(option, value) => option.vesselName === value.vesselName}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.vesselName || ''}
          options={vesselList}
          loading={loading}
          defaultValue={defaultVessel}
          selectOnFocus={false}
          onChange={(e, value) => handleChangeLanding(value)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {option.vesselName} <br /> {option.imo}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={getIntlString('Search Vessel')}
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: <IconSearch size="1.1rem" />,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

export default AutocompleteLanding;
