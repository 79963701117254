import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { FormattedMessag, useIntl } from 'react-intl';

const getIntlString = (id) => {
  const intl = useIntl();
  const searchVesselMessage = intl.formatMessage({ id: id });
  return searchVesselMessage;
};
function getDateDifference(start, end) {
  let result;
  if (start && end) {
    var duration = moment.duration(end.diff(start));

    var days = Math.floor(duration.asDays());
    var hours = duration.hours();
    var minutes = duration.minutes();

    days = days;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    result = days + 'D: ' + hours + 'H: ' + minutes + 'M';
  } else {
    result = '-D:-H:-M';
  }
  return result;
}
const numericFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      valueIsNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
    />
  );
};

function formatNumber(input) {
  if (input == null) {
    return ' - ';
  } else if (typeof input === 'number') {
    return input.toLocaleString(
      undefined, // leave undefined to use the browser's locale,
      // or use a string like 'en-US' to override it.
      { minimumFractionDigits: 2 }
    );
  } else {
    return input;
  }
}
function formatDecimalNumber(input, decimalPlaces) {
  if (input == null) {
    return ' - ';
  } else if (typeof input === 'number') {
    return input.toLocaleString(
      undefined, // leave undefined to use the browser's locale,
      // or use a string like 'en-US' to override it.
      { maximumFractionDigits: decimalPlaces ?? 0 }
    );
  } else {
    return input;
  }
}

function roundValidNumber(input, precision = 0, maxPrecision = 2) {
  if (input == null) {
    return ' - ';
  } else if (typeof input === 'number') {
    //  let roundedNumber = precision === 0 ? Math.round(input) : input.toFixed(precision)
    const string =
      precision === 0
        ? { maximumFractionDigits: 0 }
        : {
            minimumFractionDigits: precision,
            maximumFractionDigits: maxPrecision,
          };
    return input.toLocaleString('en', string);
    // if passed 0 or nothing then it will give the round off value and if want to give 1 or 2 or 3 whatever precesion value just pass the value.
  } else {
    return input;
  }
}

function getAbsNumber(input, precision = 2) {
  if (input == null) {
    return ' - ';
  } else if (typeof input === 'number') {
    return +(Math.round(input + `e+${precision}`) + `e-${precision}`);
  } else {
    return input;
  }
}

function filterFalsy(object) {
  return Object.fromEntries(
    // convert to array, map, and then fromEntries gives back the object
    Object.entries(object).map(([key, value]) => [key, value == null ? '-' : roundValidNumber(value)])
  );
}

//In furure , format will get from user selection.
function getFormat() {
  var format = 'DD-MMM-YYYY';
  return format;
}

function formatDate(date) {
  if ((date = !null)) {
    moment.utc(date).format(`${getFormat()} hh:mm:ss`);
  }
}

function trimDate(date) {
  if (date != null) return moment.utc(date).format(getFormat());
}

function trimDateWithoutyear(date) {
  if (date != null) {
    // const ReverseString = (str) => [...str].reverse().join("");
    return date.substring(5, 10).split('-').reverse().join('-');
  }
}
function trimDateTimeWithoutSeconds(date) {
  if (date != null) return moment.utc(date).format(`${getFormat()} HH:mm`);
}

/**
 *
 * @param {Array} numbers An array of numbers.
 * @return {Number} The calculated average (or mean) value from the specified
 *     numbers.
 */
function mean(numbers) {
  let total = 0;
  let i;
  for (i = 0; i < numbers.length; i += 1) {
    total += numbers[i];
  }
  return total / numbers.length;
}
function correctLongitude(point, dateLineCrossing) {
  var correctPoint;
  if (dateLineCrossing == -1) {
    correctPoint = point - 360;
  } else if (dateLineCrossing == 1) {
    correctPoint = point + 360;
  } else {
    correctPoint = point;
  }
  return correctPoint;
}

function reverseLongitude(point, dateLineCrossing) {
  var originalLongitude;
  if (dateLineCrossing == -1) {
    originalLongitude = point + 360;
  } else if (dateLineCrossing == 1) {
    originalLongitude = point - 360;
  } else {
    originalLongitude = point;
  }
  return originalLongitude;
}

function getCurrentUTCDateObject() {
  const now = new Date();
  const utcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
  console.log(utcTimestamp, new Date(utcTimestamp));
  return utcTimestamp;
}

export {
  getIntlString,
  getDateDifference,
  numericFormatCustom,
  formatNumber,
  formatDecimalNumber,
  filterFalsy,
  mean,
  roundValidNumber,
  formatDate,
  trimDate,
  trimDateTimeWithoutSeconds,
  trimDateWithoutyear,
  getAbsNumber,
  correctLongitude,
  reverseLongitude,
  getCurrentUTCDateObject,
};
