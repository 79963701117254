import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, Grid, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import { GridColumn as Column, GridNoRecords, getSelectedState } from '@progress/kendo-react-grid';
import CustomNoRowsOverlay from 'generic-component/CustomNoRowsOverlay';
import GTextField from 'generic-component/GlobalComponents/GTextField';
import KDataGrid from 'generic-component/KDataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { ColumnMenu } from './columnMenu';
import { getter } from '@progress/kendo-react-common';
import { process } from '@progress/kendo-data-query';
import { faFileMinus, faFileCircleCheck, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const styles = `
  .kendo-light-theme .k-grid td.k-selected, .kendo-light-theme .k-grid .k-table-row.k-selected > td, .kendo-light-theme .k-grid .k-table-td.k-selected, .kendo-light-theme .k-grid .k-table-row.k-selected > .k-table-td {
    background-color: #EDF0F0 ;
}
.kendo-dark-theme .k-grid td.k-selected, .kendo-dark-theme .k-grid .k-table-row.k-selected > td, .kendo-dark-theme .k-grid .k-table-td.k-selected, .kendo-dark-theme .k-grid .k-table-row.k-selected > .k-table-td {
    background-color: #243640;
}
.kendo-dark-theme select.k-picker-md {
    padding-block: 4px;
    padding-inline: 8px;
     background-color: #14252f;
     color:#ffffff;
}
.kendo-light-theme .k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-block: 8px;
    padding-inline: 12px;
    align-items: start;
    justify-content: start;
}
.kendo-dark-theme .k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-block: 8px;
    padding-inline: 12px;
    align-items: start;
    justify-content: start;
}
.kendo-dark-theme .k-grid-md td, .kendo-dark-theme .k-grid-md .k-table-td {
    padding-block: 8px;
    padding-inline: 12px;
    text-align: left;
}
.kendo-light-theme .k-grid-md td, .kendo-light-theme .k-grid-md .k-table-td {
    padding-block: 8px;
    padding-inline: 12px;
    text-align: left;
}
`;

const DATA_ITEM_KEY = 'tableIndex';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const VesselTypeIsRegistered = (props) => {
  const vesselType = props.dataItem.VesselType;
  const isEquipmentUtilizationConfigured = props.dataItem.isEquipmentUtilizationConfigured;
  const iconStyles = {
    fontSize: '1.2rem',
    marginRight: '5px',
    color: isEquipmentUtilizationConfigured ? '#2e7d32' : '#ed6c02',
  };

  const icon = isEquipmentUtilizationConfigured ? <FontAwesomeIcon icon={faFileCircleCheck} style={iconStyles} /> : <FontAwesomeIcon icon={faFileMinus} style={iconStyles} />;

  return (
    <td style={{ ...props.style }}>
      <div style={{ ...props.style, display: 'flex', justifyContent: 'space-between', alignItems: 'left', textAlign: 'left' }}>
        {vesselType}
        {icon}
      </div>
    </td>
  );
};

export default function VesselGrid({ VesselData, Operations, onRowClick, isDisabled, disabled }) {
  const theme = useTheme();
  const [filteredRows, setFilteredRows] = useState(VesselData || []);
  const [selectedVessel, setSelectedVessel] = useState(null);

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [disableGridSelection, setDisableGridSelection] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // const disabled = false;
  // const isDisabled = false;

  const filterGridData = useCallback(
    (searchTerm) => {
      if (VesselData) {
        if (!searchTerm) {
          setFilteredRows(VesselData);
        } else {
          const filteredData = VesselData.filter(
            (row) =>
              (row.VesselName && row.VesselName.toLowerCase().includes(searchTerm.toLowerCase())) ||
              (row.VesselIMO && row.VesselIMO.toLowerCase().includes(searchTerm.toLowerCase())) ||
              (row.vesselType && row.vesselType.some((type) => type.typeName.toLowerCase().includes(searchTerm.toLowerCase())))
          );
          setFilteredRows(filteredData);
        }
      }
    },
    [VesselData]
  );

  useEffect(() => {
    filterGridData(selectedVessel);
  }, [filterGridData, selectedVessel]);

  useEffect(() => {
    if (Operations.add || Operations.update) {
      setDisableGridSelection(true);
    } else {
      setDisableGridSelection(false);
    }
  }, [Operations.add, Operations.update]);

  const handleSelectionChange = async (params) => {
    if (disableGridSelection) {
      return;
    }

    const selectedRow = params.dataItem;
    const dataItemId = idGetter(selectedRow);

    // Manually update the selected state for the clicked row
    const updatedSelectedState = {};
    updatedSelectedState[dataItemId] = true;
    setSelectedState(updatedSelectedState);

    // Your existing logic for onRowClick
    onRowClick(selectedRow);
  };

  let uniqueArr = [];
  let VesselInfo = [];
  if (VesselData) {
    VesselInfo = VesselData;
    VesselInfo.forEach((element, index) => {
      uniqueArr.push({
        ...element,
        VesselName: `${element.name}`,
        VesselIMO: `${element.imo}`,
        VesselType: `${element.vesselType}`,
        tableIndex: index,
      });
    });
  }

  const rows = uniqueArr ?? uniqueArr;

  const createDataState = (dataState) => {
    return {
      result: process(rows.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: rows?.length,
    skip: 0,
  });

  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [pageSizeValue, setPageSizeValue] = React.useState('All');
  const dataStateChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === 'All' ? rows.length : event.dataState.take;
    setResult(
      process(rows, {
        ...event.dataState,
        take,
      })
    );
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setDataState({
      ...event.dataState,
      take,
    });
  };

  useEffect(() => {
    const processedData = process(rows?.slice(0), { ...dataState, skip: 0, take: rows?.length });
    setDataState({ ...dataState, skip: 0, take: rows?.length });
    setResult(processedData);
  }, [VesselData]);

  const [selectedState, setSelectedState] = React.useState({});

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    const selectedDataItem = event.dataItem;
    if (selectedDataItem) {
      onRowClick(selectedDataItem);
    }
    setSelectedState(newSelectedState);
  };

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    if (newValue.length >= 3) {
      // Update the dynamically filtered data based on the search term
      const filteredData = rows.filter((row) => {
        return Object.keys(row).some((field) => {
          const fieldValue = row[field];
          const fieldValueString = (fieldValue ? String(fieldValue) : '').toLowerCase();
          return typeof fieldValueString === 'string' && fieldValueString.includes(newValue.toLowerCase());
        });
      });

      // Set the dynamically filtered data as the result
      setResult({
        data: filteredData,
        total: filteredData.length,
      });
    } else {
      // If the search term is less than 3 characters, show all data
      setResult({
        data: rows,
        total: rows.length,
      });
    }
  };

  return (
    <Grid container item>
      <Grid container item md={12} sm={12} xs={12} columnSpacing={1}>
        <Grid item md={8} sm={6} xs={12} sx={{ pb: '4px' }}>
          <Box>
            <GTextField
              fullWidth={true}
              id="filled-basic"
              variant="filled"
              disabled={disabled}
              label="Search"
              size="small"
              margin="dense"
              helperText=""
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      position: 'absolute',
                      right: '8px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}>
                    <FontAwesomeIcon
                      style={{ fontSize: '1.2rem', color: theme.palette.mode === 'dark' ? (isDisabled ? '#6f7a7f' : '') : isDisabled ? '#a6a6a6' : '#6e6e6e' }}
                      icon={faMagnifyingGlass}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: matchesXs ? '300px' : '290px', // Adjust the width as needed
                '& .MuiOutlinedInput-root.Mui-disabled': {
                  '& fieldset': {
                    borderColor: '#A9A9A9', // Border color when disabled
                  },
                  '& input': {
                    color: '#A9A9A9', // Text color when disabled
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Card
        sx={{
          height: matchesXs ? '70vh' : '77.8vh',
          width: '100%',
          position: 'relative',
        }}>
        <style>{styles}</style>
        <KDataGrid
          data={result.data?.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          style={{ display: 'flex', flexDirection: 'column' }}
          {...dataState}
          onDataStateChange={dataStateChange}
          total={rows?.length}
          pageable={{
            buttonCount: 1,
            pageSizes: [15, 30, 60, 90, 'All'],
            pageSizeValue: pageSizeValue,
            responsive: false,
          }}
          editField="inEdit"
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          onSelectionChange={onSelectionChange}
          onRowClick={handleSelectionChange}
          selectable={{
            enabled: true,
            mode: 'single',
            cell: false,
            drag: false,
          }}
          navigatable={true}>
          <GridNoRecords>
            <CustomNoRowsOverlay />
          </GridNoRecords>
          <Column
            field="VesselName"
            title="Name"
            width="auto"
            editable={false}
            filter="text"
            cell={(props) => <td style={{ textAlign: 'left' }}>{props.dataItem[props.field]}</td>}
            columnMenu={ColumnMenu}
          />
          <Column field="VesselIMO" title="IMO" width="auto" editable={false} cell={(props) => <td style={{ textAlign: 'left' }}>{props.dataItem[props.field]}</td>} columnMenu={ColumnMenu} />
          <Column field="VesselType" title="Type" width="auto" editable={false} cell={VesselTypeIsRegistered} filter="text" columnMenu={ColumnMenu} />
        </KDataGrid>
      </Card>
    </Grid>
  );
}
