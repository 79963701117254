import React from 'react';
import { Grid, Box, Modal, Typography, Button } from '@mui/material';
import { Paper, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { InfoOutlined, ErrorOutline, WarningAmber, CheckCircleOutline, NoBackpackSharp } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
var bgColor = null;
var icon = null;

const ApiStatusModal = (props) => {
  const [open, setOpen] = React.useState(true);
  //const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false);
    props.setMessage ? props.setMessage() : '';
  };
  const theme = useTheme();

  switch (props.status) {
    case 'Error':
      icon = <ErrorOutline />;
      bgColor = theme.palette.error.main;
      break;
    case 'Info':
      icon = <FontAwesomeIcon icon={faCircleInfo} />;
      bgColor = theme.palette.mode == 'light' ? theme.palette.primary[200] : theme.palette.primary.dark;
      break;
    case 'Warning':
      icon = <WarningAmber />;
      bgColor = theme.palette.warning.dark;
      break;
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box display="flex">
          <Paper
            elevation={4}
            sx={{
              position: 'absolute',
              top: '15%',
              left: { xs: '9%', sm: '30%', md: '40%' },
              zIndex: 3,
              m: 'auto',
              mt: 22,
              alignContent: 'center',
              height: 100,
              width: 320,
            }}>
            <Card
              sx={{
                border: 'none',
              }}>
              <CardHeader
                sx={{
                  padding: '8px',
                  background: bgColor,
                  '& .MuiCardHeader-action': { mr: 0 },
                }}
                title={
                  <Grid container alignContent={'center'}>
                    <Grid item xs={8} container alignItems={'center'}>
                      {icon} <Box sx={{ mt: 0.1, ml: 1 }}>{props.status == 'Info' ? 'Information' : props.status}</Box>
                    </Grid>
                    <Grid item xs={4} container justifyContent={'flex-end'} alignItems={'center'}>
                      <CloseIcon onClick={handleClose} />
                    </Grid>

                    {/* <span style={{ paddingLeft: '205px', cursor: 'pointer' }}>
                    
                    </span> */}
                  </Grid>
                }></CardHeader>
              <CardContent sx={{ p: 2.5, pt: 1, paddingBottom: '17px !important' }}>
                <Grid sx={{ pt: 1 }} item xs={12} container justifyContent="center">
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {props.statusMessage}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

export default ApiStatusModal;
