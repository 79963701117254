/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    let user = JSON.parse(localStorage.getItem('auth'));
    if (user?.token) {
      config.headers['Authorization'] = `Bearer ${user?.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
