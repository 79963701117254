import { Box, Dialog, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import EquipUtilGrid from './Components/EquipUtilGrid';
import VesselGrid from './Components/VesselGrid';
import useAlertStore from 'store/alertStore';
import ConfirmDialog from 'generic-component/ConfirmDialog';
import CopyTo from './Components/CopyTo';
import useEquipmentUtilizationConfigStore from 'store/EquipmentUtilizationConfigurationStore';
import { useForm } from 'react-hook-form';
import { fetchJSON } from 'utils/FetchData';
import ConfirmCopy from './Components/ConfirmCopy';
import useEquipUtilGridValidation from './Components/EquipmentUtilGridValidation';
import ConfirmApplyToAll from './Components/ConfirmApplyToAll';

const Container = forwardRef((props, ref) => {
  const { VesselData, Operations, toggleOperation, onRowClick, selectedRowData, setSelectedRowData, message, setMessage, setIsRegister, equipmentData, CopyToVesselData } = props;
  const formSubmitRef = useRef(null);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  var user = JSON.parse(localStorage.getItem('auth'));

  const [open, setOpen] = useState(false);
  const [isConfirmCancel, setIsConfirmCancel] = useState(false);
  const [mode, setMode] = useState(false);
  const setSuccessAlert = useAlertStore((state) => state.setAlert);
  const [gridState, setGridState] = useState(false);
  const [openCopyTo, setOpenCopyTo] = useState(false);
  const [visibleCopyTo, setVisibleCopyTo] = useState(false);
  const setSaveAlert = useAlertStore((state) => state.setAlert);

  const EqData = useEquipmentUtilizationConfigStore((state) => state.EquipmentData);
  const setEqData = useEquipmentUtilizationConfigStore((state) => state.updateEquipment);
  const resetData = useEquipmentUtilizationConfigStore((state) => state.reset);
  const SelectedVessels = useEquipmentUtilizationConfigStore((state) => state.SelectedVessels);
  const setSelectedVessels = useEquipmentUtilizationConfigStore((state) => state.updateSelectedVessels);
  const SelectedVesselsData = useEquipmentUtilizationConfigStore((state) => state.SelectedVesselsData);
  const setSelectedVesselsData = useEquipmentUtilizationConfigStore((state) => state.updateSelectedVesselsData);
  const [confirmCopyVisible, setConfirmCopyVisible] = useState(false);
  const gridError = useEquipUtilGridValidation((state) => state.GridError);
  const [applyToAllWaring, setApplyToAllWarning] = useState(false);
  const [applyData, setApplyData] = useState();

  const handleApplyToAll = (applyToAllData) => {
    setApplyData(applyToAllData);
    setApplyToAllWarning(true);
  };

  var Disabled = false;

  const handleClickOpen = (mode, message) => {
    setMode(mode);
    setMessage(message);
    setOpen(true);
  };

  const handleSave = () => {
    formSubmitRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  useEffect(() => {
    setEqData(equipmentData);
  }, [equipmentData]);

  const updateEquipmentData = EqData?.map((item) => {
    const { tableIndex, ...rest } = item;
    return {
      ...rest,
      vesselId: selectedRowData.vesselId,
      createdBy: user?.user?.userId,
    };
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setFocus,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    context: { min: 20, max: 40 },
  });
  const onError = (errors, e) => {};
  useEffect(() => {
    reset();
  }, [Operations.update, Operations.add]);

  const saveOperation = async () => {
    if (Object.entries(gridError).length > 0) {
      handleClickOpen('ERROR', 'Unable to save the data. Please check data grid for error details.');
      return;
    }
    if (Operations.add) {
      const { data, error, isLoading } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/maintainVesselEquipmentUtilizationConfiguration`, 'POST', updateEquipmentData);
      let errDetails = JSON.parse(error);
      if (errDetails?.status == 500) {
        handleClickOpen('ERROR', 'Error Occurred! Kindly try after sometime.');
      }
      if (data?.status === 1) {
        setIsRegister(true);
        setSaveAlert({ open: true, message: 'Equipment Utilization Configuration Saved Successfully!' });
        props.toggleOperation('add');
      }
    } else if (Operations.update) {
      const { data, error, isLoading } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/maintainVesselEquipmentUtilizationConfiguration`, 'POST', updateEquipmentData);
      let errDetails = JSON.parse(error);
      if (errDetails?.status == 500) {
        handleClickOpen('ERROR', 'Error Occurred! Kindly try after sometime.');
      }
      if (data?.status === 1) {
        setIsRegister(true);
        setSaveAlert({ open: true, message: 'Equipment Utilization Configuration Updated Successfully!' });
        props.toggleOperation('update');
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleSave,
    handleDelete,
    handleOpenCopyTo,
  }));

  if (Operations.add) {
    Disabled = Operations.add;
  }
  if (Operations.update) {
    Disabled = Operations.update;
  }

  const handleDelete = async () => {
    setEqData([]);
    const { data, error } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/deleteVesselEquipmentUtilizationConfiguration?vesselId=${selectedRowData.vesselId}`, 'POST');

    let errDetails = JSON.parse(error);

    if (errDetails?.status == 404) {
      handleClickOpen('ERROR', 'Error Occurred! Kindly try after sometime.');
    }
    if (errDetails?.status == 500) {
      let errorMessage = JSON.parse(errDetails?.message);
      handleClickOpen(
        'ERROR',
        ` Error Occurred! Kindly try after sometime. 
      ${errorMessage.exception}`
      );
    }
    if (data?.status === 1) {
      resetData();
      setIsRegister(false);
      setGridState(false);
      setSaveAlert({ open: true, message: 'Equipment Utilization Configuration Deleted Successfully!' });
      props.toggleOperation('delete');
    }
  };

  const handleOpenCopyTo = () => {
    setOpenCopyTo(true);
    setVisibleCopyTo(!visibleCopyTo);
  };

  const handleCloseCopyTo = () => {
    setOpenCopyTo(false);
    setVisibleCopyTo(!visibleCopyTo);
    setSelectedVessels([]);
  };

  const handleCloseConfirmCopy = () => {
    setConfirmCopyVisible(false);
    setSelectedVesselsData([]);
    setSelectedVessels([]);
  };

  const handleCloseApplyToAllWarning = () => {
    setApplyToAllWarning(false);
  };

  const handleCopyTo = async () => {
    if (SelectedVessels.length === 0) {
      handleClickOpen('ERROR', 'Kindly select Copy To Vessels(s) before copying');
      // handleCloseCopyTo();
      return;
    } else {
      const { data, error } = await fetchJSON(
        `${window.Constants.BaseUrl}/EquipmentUtilizationConfig/getEquipmentUtilizationConfigCopyAdditionalEquipmentList?copyFromVesselId=${selectedRowData?.vesselId}&copyToVesselIds=${SelectedVessels}`
      );

      let errDetails = JSON.parse(error);

      if (errDetails?.status == 404) {
        handleClickOpen('ERROR', 'Error Occurred! Kindly try after sometime.');
      }
      if (errDetails?.status == 500) {
        let errorMessage = JSON.parse(errDetails?.message);
        handleClickOpen(
          'ERROR',
          ` Error Occurred! Kindly try after sometime.
        ${errorMessage.exception}`
        );
      }
      if (data?.status === 1) {
        if (data?.data?.length > 0) {
          setSelectedVesselsData(data?.data);
          setVisibleCopyTo(!visibleCopyTo);
          setConfirmCopyVisible(true);
        } else {
          handleCopyConfirm();
          setVisibleCopyTo(!visibleCopyTo);
        }
      }
    }
  };

  const handleCopyConfirm = async () => {
    const body = {
      copyFromVesselId: selectedRowData?.vesselId,
      copyToVesselIds: SelectedVessels,
    };
    const { data, error } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/copyEquipmentUtilizationConfiguration`, 'POST', body);

    let errDetails = JSON.parse(error);

    if (errDetails?.status == 404) {
      handleClickOpen('ERROR', 'Error Occurred! Kindly try after sometime.');
    }
    if (errDetails?.status == 500) {
      let errorMessage = JSON.parse(errDetails?.message);
      handleClickOpen(
        'ERROR',
        ` Error Occurred! Kindly try after sometime.
      ${errorMessage.exception}`
      );
    }
    if (data?.status === 1) {
      setConfirmCopyVisible(false);
      setSaveAlert({ open: true, message: 'Equipment Utilization Configuration Copied Successfully.' });
      setSelectedVessels([]);
      setSelectedVesselsData([]);
      props.toggleOperation('copyto');
    }
  };

  const handleConfirmApplyToAll = () => {
    setEqData(applyData);
    setApplyToAllWarning(false);
  };

  return (
    <form ref={formSubmitRef} onSubmit={handleSubmit(saveOperation, onError)}>
      <Grid container item columns={12} columnSpacing={0}>
        <Grid item md={4} xs={12}>
          <Paper sx={{ height: '87vh', ml: '8px', mr: '8px', pl: '8px', pr: '8px', pb: '8px', mt: '4px' }}>
            <VesselGrid VesselData={VesselData} onRowClick={onRowClick} Operations={Operations} disabled={Disabled} />
          </Paper>
        </Grid>
        <Grid item md={8} xs={12}>
          <Paper sx={{ height: '87vh', mr: '8px', mt: '4px', pl: '8px', pr: '8px', pb: '8px', ml: matchesXs && '8px' }}>
            <EquipUtilGrid
              selectedRowData={selectedRowData}
              isDisabled={!Disabled}
              Operations={Operations}
              message={message}
              setMessage={setMessage}
              equipmentData={equipmentData}
              handleApplyToAll={handleApplyToAll}
            />
          </Paper>
        </Grid>
      </Grid>

      {visibleCopyTo && (
        <CopyTo
          selectedVessel={selectedRowData}
          openCopyTo={openCopyTo}
          handleCloseCopyTo={handleCloseCopyTo}
          setSuccessAlert={setSuccessAlert}
          onRowClick={onRowClick}
          Operations={Operations}
          disabled={Disabled}
          CopyToVesselData={CopyToVesselData}
          handleCopyTo={handleCopyTo}
        />
      )}

      {confirmCopyVisible && (
        <ConfirmCopy selectedVessel={selectedRowData} SelectedVesselsData={SelectedVesselsData} handleCloseConfirmCopy={handleCloseConfirmCopy} handleCopyConfirm={handleCopyConfirm} />
      )}

      {applyToAllWaring && (
        <ConfirmApplyToAll firstEquipment={applyData[0]} handleCloseApplyToAllWarning={handleCloseApplyToAllWarning} handleConfirmApplyToAll={handleConfirmApplyToAll} open={applyToAllWaring} />
      )}

      <ConfirmDialog
        type={mode}
        open={open}
        setOpen={setOpen}
        setIsConfirm={setIsConfirmCancel}
        onClose={mode === 'DELETE' ? handleClose : ''}
        onConfirm={mode === 'DELETE' ? handleDelete : mode === 'SAVE' ? handleSave : ''}
        message={message}
      />
    </form>
  );
});

export default Container;
