import { lazy } from 'react'
import { Redirect } from 'react-router-dom'

// project imports
import MainLayout from 'layout/OtherLayout/OtherLayout'
import Loadable from 'ui-component/Loadable'
import AuthGuard from '../services/AuthGaurd'
import MainLayout2 from 'layout/MainLayout/FleetOverviewLayout'

// sample page routing
const OtherView = Loadable(lazy(() => import('views/FleetAnalysis/index')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <OtherView />,
    },
  ],
}

export default MainRoutes
