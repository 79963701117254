import React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useTheme, useMediaQuery } from '@mui/material';

const KDataGrid = (props) => {
  //   console.log(props);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <style>
        {`
          .custom-kendo-grid {
            height: ${matchesXs ? '72vh' : '77vh'};
            border: 1px solid ${theme.palette.mode === 'light' ? '#C4C4C4' : '#505050'};
            textAlign: right;
            /* Add more custom styles as needed */
        `}
      </style>
      <Grid
        {...props}
        //   style={{
        //     height: matchesXs ? '72vh' : '76.7vh',
        //     border: `1px solid ${theme.palette.mode === 'light' ? '#C4C4C4' : '#505050'}`,
        //     ...(props.style || {}),
        //   }}
        className="custom-kendo-grid"
        onRowClick={props.onRowClick}
        onCellClick={props.onCellClick}
        onSelectionChange={props.onSelectionChange}
        selectable={props.selectable}>
        {/* Map your columns to GridColumn components */}
        {/* {props?.columns?.map((column) => (
        <GridColumn key={column.field} field={column.field} title={column.headerName} width={column.width} cell={CustomLeftAlignedCell} />
      ))} */}
        {props.children}
      </Grid>
    </div>
  );
};

export default KDataGrid;
