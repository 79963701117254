import { TextField, useTheme } from '@mui/material';
import React from 'react';

const GTextField = (props) => {
  const theme = useTheme();
  return (
    <TextField
      {...props}
      variant={props.variant || 'outlined'}
      sx={{
        ...(props.sx || {}),
        //add any classname here to overide css using '& .classname
      }}></TextField>
  );
};

export default GTextField;
///how to use it
// use same as TextField from MUI pass the same props
