import { lazy } from 'react';

// project imports
// import GuestGuard from 'utils/route-guard/GuestGuard'
// import MinimalLayout from 'layout/MinimalLayout'
// import NavMotion from 'layout/NavMotion'
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AllControls = Loadable(lazy(() => import('views/AllControls')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/register',
      element: <AuthRegister />,
    },
    {
      path: '/all-controls',
      element: <AllControls />,
    },
    {
      path: '/forgot',
      element: <AuthForgotPassword />,
    },
  ],
};

export default LoginRoutes;
