import { create } from 'zustand';

const useEquipmentUtilizationConfigStore = create((set) => ({
  EquipmentData: [],
  updateEquipment: (args) =>
    set({
      EquipmentData: args,
    }),

  SelectedVessels: [],
  updateSelectedVessels: (args) =>
    set({
      SelectedVessels: args,
    }),
  SelectedVesselsData: [],
  updateSelectedVesselsData: (args) =>
    set({
      SelectedVesselsData: args,
    }),
  reset: () =>
    set({
      EquipmentData: [],
    }),
}));

export default useEquipmentUtilizationConfigStore;
