import React, { useEffect } from 'react'
import { useTitle } from 'react-use'
const NODE_ENV = process.env.NODE_ENV

const Page = ({ title, children, ...rest }) => {
  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return
    }
  }, [title])
  useTitle(title)

  return <div {...rest}>{children}</div>
}

export default Page
