import React, { useEffect, useState, useRef } from 'react';
import { Page } from 'generic-component';
import { authService } from 'services/auth-service';
import Container from './Container';
import Loader from 'ui-component/Loader';
import ApiStatusModal from 'generic-component/ApiStatusModal';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import HeaderWithCopyTo from 'generic-component/HeaderWithCopyTo';
import { fetchJSON } from 'utils/FetchData';

const EquipmentUtilizationConfiguration = () => {
  const pageRecord = authService.getAccess('/equip-utilization-config');
  const PageName = pageRecord?.pageHeader ?? '-';
  const [Loading, setLoading] = useState(true);
  const containerRef = useRef(null);
  const [operations, setOperations] = useState({
    add: false,
    update: false,
    delete: false,
    copyto: false,
  });
  const [VesselData, setVesselData] = useState('');
  const [equipmentData, setEquipmentData] = useState('');

  const [vesselError, setVesselError] = useState(null);
  const [masterError, setMasterError] = useState(null);
  const [showCannotPerformDialog, setShowCannotPerformDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  let [message, setMessage] = useState();
  const [selectedRowData, setSelectedRowData] = useState('');
  const [state, setState] = useState(true);
  const [isRegister, setIsRegister] = useState(selectedRowData?.isEquipmentUtilizationConfigured);
  const [copyToVessels, setCopyToVessels] = useState([]);

  const toggleState = (key) => {
    setOperations((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  //data fetch

  useEffect(() => {
    async function fetchVesselData() {
      const { data: VesselData, error: VesselDataError, loading: VesselDataloading } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/getEquipmentUtilizationConfigOverview`);
      setVesselError(VesselDataError);
      setVesselData(VesselData);
      setLoading(false); // Move setLoading(false) here
    }

    fetchVesselData();
  }, [operations]);

  useEffect(() => {
    async function fetchEquipmentData() {
      const {
        data: EquipmentData,
        error: equipmentError,
        loading: MasterDataloading,
      } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/getVesselEquipmentUtilizationConfiguration?vesselId=${selectedRowData.vesselId}`);
      setEquipmentData(EquipmentData);
      setLoading(false); // Move setLoading(false) here
    }
    async function fetchCopyToVesselData() {
      const {
        data: copyToVessels,
        error: equipmentError,
        loading: MasterDataloading,
      } = await fetchJSON(`${window.Constants.BaseUrl}/EquipmentUtilizationConfig/getEquipmentUtilizationConfigCopyOverview?copyFromVesselId=${selectedRowData.vesselId}`);
      setCopyToVessels(copyToVessels);
      setLoading(false); // Move setLoading(false) here
    }

    if (selectedRowData) {
      fetchEquipmentData();
      fetchCopyToVesselData();
    }
  }, [selectedRowData, operations]);

  var masterflag;
  //after api
  if (masterError) {
    masterflag = true;
  } else {
    masterflag = false;
  }
  var vesselFlag;
  //after api
  if (vesselError) {
    vesselFlag = true;
  } else {
    vesselFlag = false;
  }

  const handleSubmit = (data) => {
    containerRef.current.handleSave();
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
  };

  const handleAmend = (data) => {
    containerRef.current.handleSave();
  };
  const handleRemove = (data) => {
    containerRef.current.handleDelete();
  };

  const handleCopyTo = () => {
    containerRef.current.handleOpenCopyTo();
  };

  useEffect(() => {
    if (operations.add && selectedRowData == '') {
      setOperations(false);
      setShowCannotPerformDialog(true);
    }
  }, [operations.add, selectedRowData]);

  useEffect(() => {
    setIsRegister(selectedRowData.isEquipmentUtilizationConfigured);
  }, [selectedRowData]);

  return (
    <Page title={PageName}>
      {Loading ? (
        <Loader />
      ) : masterflag ? (
        <ApiStatusModal status={'Error'} statusMessage={'Error occured while getting Master data'} />
      ) : vesselFlag ? (
        <ApiStatusModal status={'Error'} statusMessage={'Error occured while getting Vessel list'} />
      ) : (
        <div>
          <HeaderWithCopyTo
            pageRecord={pageRecord}
            name={PageName}
            Operations={operations}
            toggleOperation={toggleState}
            onAdd={handleSubmit}
            onUpdate={handleAmend}
            message={message}
            setMessage={setMessage}
            onDelete={handleRemove}
            state={state}
            isRegister={isRegister}
            selectedRowData={selectedRowData}
            onCopyToClick={handleCopyTo}
          />
          <Container
            VesselData={VesselData?.data}
            CopyToVesselData={copyToVessels?.data}
            ref={containerRef}
            Operations={operations}
            toggleOperation={toggleState}
            onRowClick={handleRowClick}
            selectedRowData={selectedRowData}
            setSelectedRowData={setSelectedRowData}
            message={message}
            setMessage={setMessage}
            setIsRegister={setIsRegister}
            equipmentData={equipmentData?.data}
          />
        </div>
      )}

      {showCannotPerformDialog && (
        <Dialog open={showCannotPerformDialog} onClose={() => setShowCannotPerformDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Unable to Perform the Operation'}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"> {'No record selected for this operation, kindly select the Vessel first.'}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setShowCannotPerformDialog(false);
                setShowDeleteConfirmation(false);
                setOperations(false);
              }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Page>
  );
};

export default EquipmentUtilizationConfiguration;
