import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';
import * as AllIcons from '@tabler/icons';
// project imports
import NavGroup from './NavGroup';
//import menuItem from 'menu-items'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const pageAccess = JSON.parse(localStorage.getItem('auth'));

  const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [],
  };
  if (pageAccess?.accessClaims) {
    pageAccess?.accessClaims.forEach(async (item) => {
      const child = [];
      var isSingleNode = item.parentAppPageInfoId == -1;
      if (isSingleNode) {
        var isCollapsible = !item.isLeafeNode && item.parentAppPageInfoId == -1;
        if (isCollapsible) {
          const subChild = pageAccess?.accessClaims.filter((x) => x.parentAppPageInfoId == item.pageInfoId);
          subChild.sort((a, b) => a.setIndex - b.setIndex);
          subChild.forEach((items, index) => {
            subChild[index] = {
              id: items.title,
              title: items.title,
              type: 'item',
              url: items.url,
              envCode: items.environmentCode,
            };
          });
          child.push(...subChild);
        }
        //const iconComponent = await getIconComponent(item.iconName)
        var element = {
          id: item.title,
          title: item.title,
          type: isCollapsible ? 'collapse' : 'item',
          ...(!isCollapsible && { url: item.url }),
          children: child,
          icon: AllIcons[item.iconName],
          envCode: item.environmentCode,
          //  breadcrumbs: false,
        };
        other.children.push(element);
      }
    });
  }
  const menuItems = {
    items: [other],
  };
  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
