import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, useMediaQuery, Divider } from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import MenuList from './MenuList'
import LogoSection from '../../generic-layout-componant/LogoSection'

import { openDrawer } from 'store/slices/menu'
import { useDispatch, useSelector } from 'store'

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const dispatch = useDispatch()
  const { drawerOpen } = useSelector((state) => state.menu)

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'block' } }}>
        <Box sx={{ display: 'flex', py: 0.375, pl: 1.625, pb: 0, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
    ),
    []
  )

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 56px)',
          paddingLeft: '6px',
          paddingRight: '6px',
        }}>
        <Box sx={{ mt: 2 }}>
          <MenuList />
        </Box>
        {/* <MenuCard /> */}
        {/* <Stack direction="row" justifyContent="center" alignItems="flex-end" sx={{ mb: 2 }}>
                    <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
                </Stack> */}
      </PerfectScrollbar>
    ),
    // eslint-disable-next-line
    [matchUpMd]
  )

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            // width: '100%',
            maxWidth: 300,
            minWidth: 215,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '0px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit">
        {drawerOpen && logo}
        <Divider sx={{ mt: 0, mb: 0 }} />
        {drawerOpen && drawer}
      </Drawer>
    </Box>
  )
}

Sidebar.propTypes = {
  window: PropTypes.object,
}

export default memo(Sidebar)
