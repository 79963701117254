import {
  Grid,
  Alert,
  AlertTitle,
  Snackbar,
  Typography,
  Slide,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSaluting } from "@fortawesome/pro-light-svg-icons";
import PropTypes, { object } from "prop-types";
import { useTheme } from "@mui/styles";

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const SuccessAlert = ({ open, setOpen, message }) => {
  const theme = useTheme();
  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === "clickaway") {
          return;
        } else {
          setOpen(false);
        }
      }}
      sx={{ mr: 0, right: "8em !important", top: "3px !important" }}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={TransitionRight}
      key="transitionRight"
      //   sx={{ height: "100%" }}
      //   anchorOrigin={{
      //     vertical: "top",
      //     horizontal: "center",
      //   }}
    >
      <Alert
        severity="success"
        icon={
          <FontAwesomeIcon
            icon={faFaceSaluting}
            size="sm"
            beat
            color={theme.palette.mode === "light" ? "#ffffff" : "#ffffff"}
          />
        }
        variant="filled"
        sx={{
          pr: "16px",
          pt: 0,
          pb: 0,
          pl: "16px",
          m: 0,
          backgroundColor:
            theme.palette.mode === "light" ? "#4CAF50" : "#2E7D32", // severity success and dark both are same. ues this if need to change to light or main
          maxWidth: "350px",
        }}
      >
        <Typography
          variant="h4"
          color={theme.palette.mode === "light" ? "#ffffff" : "#ffffff"}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

SuccessAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
export default SuccessAlert;
