import { useState } from 'react';

const { faQuestionCircle, faEnvelope } = require('@fortawesome/pro-light-svg-icons');
const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');
const { IconButton, Box, Menu, MenuItem } = require('@mui/material');
const { useSupportContext } = require('SSPL_Support/contexts/SupportProvider');

const SupportButton = () => {
  const { isSupportPageOpen, toggleSupport } = useSupportContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => {
          handleClick(e);
        }}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={() => {
            toggleSupport(!isSupportPageOpen);
            handleClose();
          }}>
          <IconButton color="secondary" variant="light" size="small" sx={{ color: 'text.primary' }} aria-label="open profile" aria-controls={open ? 'profile-grow' : undefined} aria-haspopup="true">
            <FontAwesomeIcon icon={faEnvelope} />
          </IconButton>
          Support Request
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SupportButton;
