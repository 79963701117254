import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, IconButton } from '@mui/material';
import HighlightCanvas from 'SSPL_Support/components/HighlightCanvas';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-light-svg-icons';

const ScreenshotEditModal = ({ editModalOpen, closeEditModal, screenshot, setScreenshot }) => {
  const highlightCanvasRef = useRef(null);
  const [isHighlighting, setIsHighlighting] = useState(true);
  const [isHideContent, setIsHideContent] = useState(false);

  const handleSaveImage = () => {
    setScreenshot(highlightCanvasRef.current.toDataURL());
    handleEditModalClose();
  };

  const handleEditModalClose = () => {
    if (closeEditModal) closeEditModal();
  };

  const toggleMode = (mode) => {
    if (mode === 'highlight') {
      setIsHighlighting(true);
      setIsHideContent(false);
    } else {
      setIsHideContent(true);
      setIsHighlighting(false);
    }
  };
  return (
    <Dialog open={editModalOpen} onClose={closeEditModal} maxWidth="xl" fullWidth>
      <DialogTitle id="alert-dialog-title" sx={{ py: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">Highlight Areas</Typography>
          <IconButton color="error" onClick={closeEditModal}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box>
          <HighlightCanvas image={screenshot} isHighlighting={isHighlighting} isHideContent={isHideContent} handleSaveImage={handleSaveImage} highlightCanvasRef={highlightCanvasRef} />
          {/* <img width="100%" srcSet={screenshot} alt="Captured screenshot" loading="lazy" /> */}
        </Box>
      </DialogContent>
      <DialogActions sx={{ py: 1 }}>
        <Button
          onClick={() => {
            toggleMode('highlight');
          }}
          color="info"
          variant={isHighlighting ? 'contained' : 'outlined'}>
          Highlight
        </Button>
        <Button
          onClick={() => {
            toggleMode('hide');
          }}
          color="info"
          variant={isHideContent ? 'contained' : 'outlined'}>
          Hide Content
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleSaveImage();
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ScreenshotEditModal.propTypes = {
  editModalOpen: PropTypes.bool,
  closeEditModal: PropTypes.func,
  screenshot: PropTypes.string,
  setScreenshot: PropTypes.func,
};

export default ScreenshotEditModal;
