import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; // eslint-disable import/no-webpack-loader-syntax, import/no-unresolved
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useTheme } from '@mui/styles';
mapboxgl.accessToken = window.MapConstants.AccessToken;

const SummaryMap = () => {
  const theme = useTheme();
  const mapContainer = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      attributionControl: false,
      style: theme.palette.mode == 'light' ? window.MapConstants.Styles.Light : window.MapConstants.Styles.Dark,
      zoom: 1.5,
      preserveDrawingBuffer: true,
    });
  }, [theme.palette.mode]);

  return <div ref={mapContainer} className="map-container" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%' }}></div>;
};
export default SummaryMap;
