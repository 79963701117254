import { Button, Stack, Box, FormLabel, IconButton, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPenSquare, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { LoadingButton } from '@mui/lab';
import DomToImage from 'dom-to-image-more';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

const CaptureScreenshot = ({ screenshot, setScreenshot, setEditModalOpen, isSupportPageOpen }) => {
  const [capturing, setCapturing] = React.useState(false);
  const captureText = ['A screenshot will help us better understand issue. (optional)', 'Attached Screenshot'];
  const [doesPageHasIframes, setDoesPageHasIframes] = React.useState(false);

  function nodefilter(node) {
    console.log(node);
    try {
      if (node.hasAttribute('data-html2canvas-ignore')) {
        return node.getAttribute('data-html2canvas-ignore') !== 'true';
      }
      return true;
    } catch (err) {
      return true;
    }
  }

  React.useEffect(() => {
    checkPageHasIframes();
  }, [isSupportPageOpen]);

  const checkPageHasIframes = () => {
    var iframes = document.getElementsByTagName('iframe');
    setDoesPageHasIframes(iframes.length > 0);
  };

  const captureScreenshot = async () => {
    setCapturing(true);
    DomToImage.toPng(document.body, { filter: nodefilter })
      .then(function (dataUrl) {
        setScreenshot(dataUrl);
        setCapturing(false);
      })
      .catch(function (error) {
        console.log(error);
        alert('Error during capture, please try again later.');
        setCapturing(false);
      });
  };

  return (
    <>
      {doesPageHasIframes ? (
        <>
          <Typography color="InfoText">
            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; The Capture Screenshot option is not available for this page. Please take a screenshot manually and attach the image.
          </Typography>
        </>
      ) : (
        <>
          <FormLabel>{screenshot ? captureText[1] : captureText[0]}</FormLabel>
          <Stack direction="column" justifyContent="center" alignItems="center">
            {!screenshot && (
              <LoadingButton loading={capturing} variant="contained" size="small" color="secondary" loadingPosition="start" startIcon={<FontAwesomeIcon icon={faCamera} />} onClick={captureScreenshot}>
                Capture screenshot
              </LoadingButton>
            )}
            {screenshot && (
              <>
                <Box sx={{ position: 'relative', width: '100%' }}>
                  <img width="100%" srcSet={screenshot} alt="Captured screenshot" loading="lazy" />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '40%',
                      left: '45%',
                      transform: 'translate(-40%, -40%)',
                    }}>
                    <Button size="small" variant="contained" startIcon={<FontAwesomeIcon icon={faPenSquare} />} onClick={() => setEditModalOpen(true)}>
                      Highlight info
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0%',
                      right: '0%',
                      transform: 'translate(0, 0)',
                    }}>
                    <IconButton variant="contained" size="small" color="error" shape="rounded" sx={{ position: 'fixed', right: 1 }} onClick={() => setScreenshot('')}>
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </Box>
                </Box>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

CaptureScreenshot.propTypes = {
  screenshot: PropTypes.string,
  setScreenshot: PropTypes.func,
  setEditModalOpen: PropTypes.func,
  isSupportPageOpen: PropTypes.bool,
};

export default CaptureScreenshot;
