// material-ui
import { LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 45.5,
  left: 0,
  zIndex: 1301,
  width: '100%',
})
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 2.5,
  color: 'primary',
  // [`&.${linearProgressClasses.colorPrimary}`]: {
  //   backgroundColor: "#c8c8ff",
  // },
  // [`& .${linearProgressClasses.bar}`]: {
  //   backgroundColor: "#485dc9",
  // },
}))

const Loader = () => (
  <LoaderWrapper>
    <BorderLinearProgress />
  </LoaderWrapper>
)

export default Loader
