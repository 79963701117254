import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect, Transformer, Image } from 'react-konva';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const HighlightCanvas = ({ highlightCanvasRef, isHighlighting, isHideContent, image }) => {
  const [rectangles, setRectangles] = useState([]);
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
  const imageRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: window.innerWidth * 0.9, height: window.innerHeight * 0.7 });
  const [canvasImage, setCanvasImage] = useState();
  const transformerRef = useRef(null);
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const computedStyle = window.getComputedStyle(elementRef.current);
      setCanvasSize({
        width: parseFloat(computedStyle.width),
        height: parseFloat(computedStyle.height),
      });
    }
  }, []);

  const getFillColor = () => {
    if (isHideContent) return 'rgba(0,0,0,1)';
    if (isHighlighting) return 'rgba(0,0,255,0.2)';
    return 'rgba(0,0,255,0.2)';
  };

  useEffect(() => {
    if (image) {
      const img = new window.Image();
      img.src = image;
      setCanvasImage(img);
    }
  }, [image]);

  const handleMouseDown = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();

    setRectangles([
      ...rectangles,
      {
        x,
        y,
        width: 0,
        height: 0,
      },
    ]);
    setSelectedShapeIndex(rectangles.length);
  };

  const handleMouseMove = (e) => {
    if (selectedShapeIndex !== null) {
      const { x, y } = e.target.getStage().getPointerPosition();
      const updatedRectangles = [...rectangles];
      updatedRectangles[selectedShapeIndex].width = x - rectangles[selectedShapeIndex].x;
      updatedRectangles[selectedShapeIndex].height = y - rectangles[selectedShapeIndex].y;
      updatedRectangles[selectedShapeIndex].fillStyle = getFillColor();
      setRectangles(updatedRectangles);
    }
  };

  const handleMouseUp = () => {
    setSelectedShapeIndex(null);
  };

  return (
    <Box ref={elementRef}>
      <Stage
        width={canvasSize.width}
        height={canvasSize.height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        ref={highlightCanvasRef}>
        <Layer key="1">
          <Image image={canvasImage} ref={imageRef} draggable={false} width={canvasSize.width} height={canvasSize.height} />
        </Layer>
        <Layer key="2">
          {rectangles.map((rect, i) => (
            <>
              <Rect key={i} x={rect.x} y={rect.y} width={rect.width} height={rect.height} fill={rect.fillStyle} draggable />
            </>
          ))}
          {selectedShapeIndex !== null && <Transformer ref={transformerRef} rotateEnabled={false} borderEnabled={true} keepRatio={true} />}
        </Layer>
      </Stage>
    </Box>
  );
};

HighlightCanvas.propTypes = {
  image: PropTypes.string,
  isHighlighting: PropTypes.bool,
  isHideContent: PropTypes.bool,
  highlightCanvasRef: PropTypes.any,
};

export default HighlightCanvas;
