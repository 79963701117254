// MyContext.js
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SupportContext = createContext();

export const SupportProvider = ({ children }) => {
  const [isSupportPageOpen, setIsSupportPageOpen] = useState(false);

  const toggleSupport = (isOpen) => {
    setIsSupportPageOpen(isOpen);
  };

  return <SupportContext.Provider value={{ isSupportPageOpen, toggleSupport }}>{children}</SupportContext.Provider>;
};

SupportProvider.propTypes = {
  children: PropTypes.node
};

export const useSupportContext = () => {
  return React.useContext(SupportContext);
};
