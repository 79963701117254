import { createRef, useLayoutEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes, { object } from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faFaceThinking,
//   faFaceGlasses,
//   faFaceSurprise,
//   faFaceWeary,
//   faFaceScream,
// } from "@fortawesome/pro-regular-svg-icons";
// import { faFaceThinking, faFaceGlasses, faFaceSurprise, faFaceWeary, faFaceScream, faFaceCowboyHat, faFaceMeh } from '@fortawesome/pro-light-svg-icons'
// import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { faFaceThinking, faFaceGlasses, faFaceSurprise, faFaceWeary, faFaceScream, faFaceCowboyHat, faFaceMeh } from '@fortawesome/pro-light-svg-icons';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import {
  DialogContentText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  AccordionActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CapitalizedButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
}));

const RedCapitalizedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  border: `1px solid ${theme.palette.error.main}`,
  '&:hover': {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  textTransform: 'none',
}));
const SuccessCapitalizedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.success.dark,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  border: `1px solid ${theme.palette.success.dark}`,
  '&:hover': {
    color: theme.palette.getContrastText(theme.palette.success.dark),
    backgroundColor: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.dark}`,
  },
  textTransform: 'none',
}));

function ConfirmationIcon(props) {
  switch (props.mode) {
    case 'DELETE':
    case 'SAVE':
    case 'SUBMIT':
      // return <DeleteForeverTwoToneIcon fontSize="large" />;
      return <FontAwesomeIcon icon={faFaceThinking} size="sm" />;
    case 'NOTIFY':
      // return <NotificationsTwoToneIcon fontSize="large" />;
      return <FontAwesomeIcon icon={faFaceGlasses} size="sm" />;
    //return <FontAwesomeIcon icon={faFaceCowboyHat} size="sm" />;
    case 'VALIDATIONERROR':
      // return <RuleTwoToneIcon fontSize="large" />;
      //return <FontAwesomeIcon icon={faFaceSurprise} size="sm" />;
      return <FontAwesomeIcon icon={faFaceMeh} siez="sm" />;
    case 'ERROR':
      return <FontAwesomeIcon icon={faFaceWeary} size="sm" />;
    //return <FontAwesomeIcon icon={faFaceScream} size="lg" />;
    default:
      return '';
  }
}

function ConfirmationMessage(props) {
  const theme = useTheme();
  return (
    <Grid container spacing={1.5} columns={12}>
      {props.mode !== 'CUSTOM' && (<Grid item xs={1}>
        <Typography variant="h1">
          <ConfirmationIcon mode={props.mode} />
        </Typography>
      </Grid>)}
      <Grid item style={{ display: 'flex', alignItems: 'center' }} xs={11}>
        {typeof props.message == 'object' ? (
          props.message.length > 1 ? (
            <List
              // sx={{ listStyleType: "disc", pl: 2 }}
              subheader={
                <ListSubheader>
                  {props.mode === 'VALIDATIONERROR' ? (
                    <DialogContentText
                      sx={{
                        fontSize: '1.038rem',
                        fontWeight: 500,
                        color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                      }}>
                      Unable to perform the Operation. Kindly evaluate below list and try again:
                    </DialogContentText>
                  ) : (
                    ''
                  )}
                </ListSubheader>
              }>
              {props.message.map((data, index) => {
                return (
                  <ListItem
                    key={index}
                    // sx={{ display: "list-item" }}
                  >
                    <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(2) }}>
                      <FontAwesomeIcon icon={faCircleSmall} size="xs" />
                    </ListItemIcon>
                    <ListItemText primary={data.Error} />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <List
              subheader={
                <ListSubheader>
                  {props.mode === 'VALIDATIONERROR' ? (
                    <DialogContentText
                      sx={{
                        fontSize: '1.038rem',
                        fontWeight: 500,
                        color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                      }}>
                      Unable to perform the Operation. Kindly evaluate below and try again:
                    </DialogContentText>
                  ) : (
                    ''
                  )}
                </ListSubheader>
              }>
              <ListItem>
                  <ListItemText primary={props.message[0].Error} />
              </ListItem>
            </List>
          )
        ) : (
          <DialogContentText
            sx={{
              fontSize: '1.038rem',
              fontWeight: 500,
              color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
            }}>
            {/* <span
              style={{
                //whiteSpace: "pre",
                whitespace: 'normal !important',
                wordwrap: 'break-word !important',
              }}>
              {props.message}
            </span> */}
            {props.message?.split('\n').map((t, key) => {
              return <div key={key}>{t=== ' ' ? <p>{' '}</p> : t}</div>;
            })}
          </DialogContentText>
        )}
      </Grid>
    </Grid>
  );
}
function ConfirmationAction(props) {
  const theme = useTheme();
  // Below is to set effect of focus visibility when autofocus is required to set. As of now commenting because we don't want to keep autofocus by default.
  // const actionRef = createRef();

  // useLayoutEffect(() => {
  //   if (props.dialogRendered && actionRef.current) {
  //     actionRef.current.focusVisible();
  //   }
  // }, [props.dialogRendered]);

  switch (props.mode) {
    case 'DELETE':
      return (
        <>
          <CapitalizedButton onClick={props.handleClose}>Cancel</CapitalizedButton>
          <Button
            variant="contained"
            onClick={props.handleConfirm}
            //action={actionRef} // For autofocus visibility
            // autoFocus={true}
            sx={{
              color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
              backgroundColor: theme.palette.mode === 'light' ? '#f44336' : '#f44336',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light' ? '#D32F2F ' : '#D32F2F',
              },
            }}>
            Yes, I'm Sure
          </Button>
        </>
      );
    case 'NOTIFY':
      return (
        <Button
          variant="contained"
          onClick={props.handleConfirm}
          //action={actionRef} // For autofocus visibility
          // autoFocus={true}
          sx={{
            color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
            backgroundColor: theme.palette.mode === 'light' ? '#1976D2' : '#0288d1',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'light' ? '#1976D2' : '#1976D2',
            },
          }}>
          Dismiss
        </Button>
      );
    case 'VALIDATIONERROR':
    case 'ERROR':
      return (
        <Button
          variant="contained"
          //action={actionRef} // For autofocus visibility
          // autoFocus={true}
          onClick={props.handleConfirm}
          sx={{
            color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
            backgroundColor: theme.palette.mode === 'light' ? '#E65100' : '#E65100',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'light' ? '#E65100' : '#C94701',
            },
          }}>
          Ok
        </Button>
      );
    case 'SAVE':
    case 'CUSTOM':
      return (
        <>
          <CapitalizedButton onClick={props.handleClose}>Cancel</CapitalizedButton>
          <Button
            variant="contained"
            onClick={props.handleConfirm}
            //action={actionRef} // For autofocus visibility
            // autoFocus={true}
            sx={{
              color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
              backgroundColor: theme.palette.mode === 'light' ? '#4CAF50' : '#4CAF50',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light' ? '#2E7D32' : '#2E7D32',
              },
            }}>
            Yes, I'm Sure
          </Button>
        </>
      );
    case 'SUBMIT':
      return (
        <>
          <CapitalizedButton onClick={props.handleClose}>Cancel</CapitalizedButton>
          <Button
            variant="contained"
            //action={actionRef} // For autofocus visibility
            // autoFocus={true}
            onClick={props.handleConfirm}
            sx={{
              color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
              backgroundColor: theme.palette.mode === 'light' ? '#f44336' : '#f44336',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light' ? '#D32F2F' : '#D32F2F',
              },
            }}>
            Yes, Submit
          </Button>
        </>
      );
  }
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },

  // "& .MuiDialog-container": {
  //   justifyContent: "flex-start",
  //   alignItems: "baseline",
  // },
  '& .MuiPaper-root': {
    //   //marginTop: "80px",
    //   // marginLeft: "64px",
    //   minWidth: "250px",
    width: '650px',
    //padding: 0,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function BootstrapDialogTitle(props) {
  const theme = useTheme();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other} style={{ cursor: 'move' }} id="draggable-dialog-title" sx={{ pt: 1 }}>
      <Grid container columns={12}>
        <Grid
          item
          xs={11.5}
          sx={{
            fontSize: '1.5rem',
            fontWeight: 700,
            color: theme.palette.mode === 'light' ? '#212121' : '#e4e8f7',
          }}>
          {children}
        </Grid>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              // position: "absolute",
              // right: 8,
              // top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledSnackbar = styled(Snackbar)(({ theme, props }) => ({
  '& .MuiSnackbarContent-root': {
    padding: theme.spacing(0),
  },
  '& .MuiSnackbarContent-message': {
    padding: theme.spacing(0),
  },
}));

export default function ConfirmDialog({ type, open, setOpen, setIsConfirm, onClose, onConfirm, message, customTitle }) {
  const theme = useTheme();
  const [dialogRendered, setDialogRendered] = useState(false);
  const handleClose = (evet, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'clickaway')) return;
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (setIsConfirm) {
      setIsConfirm(true);
    }
    setOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  const deleteMessage = 'You are about to delete this record. This action CANNOT be undone.';
  const saveMessage = 'You are about to save this information.';
  const submitMessage = 'You are about to submit this information.\nOnce submitted, the entry CANNOT be amended or deleted.';
  const title =
    type === 'DELETE'
      ? 'Delete This Record?'
      : type === 'SAVE'
      ? 'Save This Information?'
      : type === 'SUBMIT'
      ? 'Submit This Information?'
      : type === 'NOTIFY'
      ? 'Notification'
      : type === 'VALIDATIONERROR'
      ? 'Attention!'
      : type === 'ERROR'
      ? 'Error'   
      : type === 'CUSTOM'
                  ? customTitle
                  : '';
  const dialogEntered = () => {
    setDialogRendered(true);
  };
  return type === 'VALIDATIONERROR' ? (
    <Draggable>
      <StyledSnackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message={
          <>
            <Accordion defaultExpanded disableGutters>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: 'row-reverse' }}>
                <Grid container columns={12} sx={{ pl: 2 }}>
                  <Grid item xs={11.5}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        color: theme.palette.mode === 'light' ? '#212121' : '#e4e8f7',
                      }}>
                      Attention!
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5}>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1.5}>
                  <Grid item>
                    <Typography variant="h1">
                      <ConfirmationIcon mode={type} />
                    </Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    {typeof message == 'object' ? (
                      message.length > 1 ? (
                        <List
                          // sx={{ listStyleType: "disc", pl: 2 }}
                          subheader={
                            <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                              {type === 'VALIDATIONERROR' ? (
                                <DialogContentText
                                  sx={{
                                    fontSize: '1.038rem',
                                    fontWeight: 500,
                                    color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                                  }}>
                                  Unable to perform the Operation. Kindly evaluate below list and try again:
                                </DialogContentText>
                              ) : (
                                ''
                              )}
                            </ListSubheader>
                          }>
                          {message.map((data, index) => {
                            return (
                              <ListItem
                                key={index}
                                // sx={{ display: "list-item" }}
                              >
                                <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(2) }}>
                                  <FontAwesomeIcon icon={faCircleSmall} size="xs" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontSize: '1.038rem',
                                        fontWeight: 500,
                                        color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                                      }}
                                      component="span"
                                      variant="body2">
                                      {data.Error}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : (
                        <List
                          subheader={
                            <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                              {type === 'VALIDATIONERROR' ? (
                                <DialogContentText
                                  sx={{
                                    fontSize: '1.038rem',
                                    fontWeight: 500,
                                    color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                                  }}>
                                  Unable to perform the Operation. Kindly evaluate below and try again:
                                </DialogContentText>
                              ) : (
                                ''
                              )}
                            </ListSubheader>
                          }>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    fontSize: '1.038rem',
                                    fontWeight: 500,
                                    color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                                  }}
                                  component="span"
                                  variant="body2">
                                  {message[0].Error}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      )
                    ) : (
                      <DialogContentText
                        sx={{
                          fontSize: '1.038rem',
                          fontWeight: 500,
                          color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
                        }}>
                        <span
                          style={{
                            //whiteSpace: "pre",
                            whitespace: 'normal !important',
                            wordwrap: 'break-word !important',
                          }}>
                          {message}
                        </span>
                      </DialogContentText>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        }>
        {/* <Alert
          sx={{
            width: "100%",
            backgroundColor:
              theme.palette.mode === "light" ? "#FFF8E1" : "#ffc107",
          }}
          icon={false}
          onClose={handleClose}
        >
          <ConfirmationMessage message={message} mode={type} />
        </Alert> */}
      </StyledSnackbar>
    </Draggable>
  ) : (
    <div>
      <BootstrapDialog //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        TransitionProps={{
          onEntered: dialogEntered,
        }}
        scroll={'paper'}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        maxWidth="md">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent>
          <ConfirmationMessage message={type === 'DELETE' ? deleteMessage : type === 'SAVE' ? saveMessage : type === 'SUBMIT' ? submitMessage : message} mode={type} />
        </DialogContent>
        <DialogActions>
          <ConfirmationAction mode={type} handleClose={handleClose} handleConfirm={handleConfirm} dialogRendered={dialogRendered} />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  type: PropTypes.oneOf(['DELETE', 'SAVE', 'NOTIFY', 'VALIDATIONERROR', 'ERROR', 'SUBMIT']).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setIsConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
