import api from 'utils/api';
import { create } from 'zustand';

const useSelectedVesselStore = create((set) => ({
  error: null,
  loading: true,
  vesselList: [],

  selectedVessel: {
    vessel_ID: undefined,
    vessel_Object_ID: undefined,
    vesselName: '',
    imo: '',
    portCallId: undefined,
    legnumber: '',
  },
  updatePortcallId: (newPortCallId, vesselID, vesselObjectID, vesselName, imo, legnumber) =>
    set({
      selectedVessel: {
        vessel_ID: vesselID,
        vessel_Object_ID: vesselObjectID,
        portCallId: newPortCallId,
        vesselName: vesselName,
        imo: imo,
        legnumber: legnumber,
      },
    }),
  update: (args) =>
    set({
      selectedVessel: args,
    }),

  fetch: async () => {
    try {
      const user = JSON.parse(localStorage.getItem('auth'));
      const response = await api(`${window.Constants.BaseUrl}/Vessel/GetAllVessels/?userId=${user?.user?.userId}`).json();

      const Filteredres = response.data.map((x) => ({
        vessel_ID: x?.vesselId,
        vessel_Object_ID: x?.vesselObjectId,
        vesselName: x?.vesselName,
        imo: x?.imo,
        vesselType: x?.vesselType,
        ...x,
      }));
      set({ error: null, loading: false, vesselList: Filteredres });
    } catch (error) {
      set({
        error: error,
        loading: false,
        vesselList: [],
      });
    }
  },
}));

export default useSelectedVesselStore;
