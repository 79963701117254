import { lazy } from "react";
import { Redirect } from "react-router-dom";

// project imports
import MainLayout from "layout/OtherLayout/OtherLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "../services/AuthGaurd";

// sample page routing
const FuelChangeOverLog = Loadable(
  lazy(() => import("views/FuelChangeOverLog"))
);
const MaintenanceEventTracker = Loadable(
  lazy(() => import("views/MaintenanceEventTracker"))
);
// ==============================|| MAIN ROUTING ||============================== //

const OperationalLogsRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/fuel-change-over-log",
      element: <FuelChangeOverLog />,
    },
    {
      path: "/maintenance-event-tracker",
      element: <MaintenanceEventTracker />,
    },
  ],
};

export default OperationalLogsRoutes;
