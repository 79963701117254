import { create } from 'zustand'

const useOwnerNameStore = create((set) => ({
  ultimateOwner: '',
  update: (args) =>
    set({
      ultimateOwner: args,
    }),
}))

export default useOwnerNameStore
