import { lazy } from 'react'
import { Redirect } from 'react-router-dom'

// project imports
import MainLayout from 'layout/OtherLayout/OtherLayout'
import Loadable from 'ui-component/Loadable'
import AuthGuard from '../services/AuthGaurd'

// sample page routing
const AlarmConfiguration = Loadable(lazy(() => import('views/AlarmConfiguration')))

const UserManagement = Loadable(lazy(() => import('views/UserManagement')))
const EquipmentType = Loadable(lazy(() => import('views/EquipmentType')));
// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/alarm-configuration',
      element: <AlarmConfiguration />,
    },
    {
      path: '/user-management',
      element: <UserManagement />,
    },
    {
      path: '/equipment-type',
      element: <EquipmentType />,
    }
  ],
}

export default AdminRoutes
