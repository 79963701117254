import { Grid, IconButton, Paper, Typography, useMediaQuery, useTheme, Button, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faFaceThinking } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@mui/material/styles';
import PropTypes, { object } from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Draggable from 'react-draggable';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
  '& .MuiPaper-root': {
    width: '650px',
  },
}));
function BootstrapDialogTitle(props) {
  const theme = useTheme();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other} style={{ cursor: 'move' }} id="draggable-dialog-title" sx={{ pt: 1 }}>
      <Grid container columns={12}>
        <Grid
          item
          xs={11.5}
          sx={{
            fontSize: '1.5rem',
            fontWeight: 700,
            color: theme.palette.mode === 'light' ? '#212121' : '#e4e8f7',
          }}>
          {children}
        </Grid>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const ConfirmApplyToAll = ({ handleCloseApplyToAllWarning, handleConfirmApplyToAll, firstEquipment, open }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogRendered, setDialogRendered] = useState(false);

  const dialogEntered = () => {
    setDialogRendered(true);
  };
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      onClose={handleCloseApplyToAllWarning}
      TransitionProps={{
        onEntered: dialogEntered,
      }}
      scroll={'paper'}
      PaperComponent={PaperComponent}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      open={open}
      width={matchesXs ? '100%' : '50%'}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseApplyToAllWarning}>
        Apply The Running Hours To All Fields?
      </BootstrapDialogTitle>
      <DialogContent>
        <Grid container spacing={1.5} columns={12}>
          <Grid item xs={1}>
            <Typography variant="h1">
              <FontAwesomeIcon icon={faFaceThinking} size="sm" />
            </Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'left' }} xs={11}>
            <DialogContentText
              sx={{
                fontSize: '1.038rem',
                fontWeight: 500,
                color: theme.palette.mode === 'light' ? '#616161 ' : '#d5d9e9',
              }}>
              You are about to copy all the Equipment Running Hours of {firstEquipment.equipmentMasterName} to all other equipments
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button sx={{ textTransform: 'capitalize', mr: '8px' }} onClick={handleCloseApplyToAllWarning}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
              backgroundColor: theme.palette.mode === 'light' ? '#4CAF50' : '#4CAF50',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light' ? '#2E7D32' : '#2E7D32',
              },
            }}
            onClick={handleConfirmApplyToAll}>
            Yes, I'm Sure
          </Button>
        </Grid>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ConfirmApplyToAll;
