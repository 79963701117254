import { Link as RouterLink } from 'react-router-dom'

// material-ui
import { Link, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project imports
import { DASHBOARD_PATH } from 'config'
import Logo from 'ui-component/Logo'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const theme = useTheme()
  const LogotitleColor = theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]
  return (
    <>
      <Link component={RouterLink} to={DASHBOARD_PATH}>
        <Logo />
      </Link>
      <Box
        component="div"
        style={{
          color: LogotitleColor,
          fontFamily: 'sans-serif',
          fontSize: '17.5px',
          marginTop: 14,
          letterSpacing: 0.5,
          fontWeight: 700,
        }}>
        WHEELHOUSE
      </Box>
    </>
  )
}

export default LogoSection
