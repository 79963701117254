import { create } from 'zustand';

const useEquipUtilGridValidation = create((set) => ({
  GridError: [],
  setGridError: (args) => {
    set((state) => ({
      GridError: { ...state.GridError, [args.key]: args.value },
    }));
  },

  clearGridError: (key) => {
    set((state) => ({
      GridError: delete state.GridError[key],
    }));
  },
}));

export default useEquipUtilGridValidation;
