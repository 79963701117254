// import api from 'utils/api'
import { create } from 'zustand'

const useSelectedVesselStoreLanding = create((set) => ({
  error: null,
  loading: true,
  vesselList: [],

  Vessel: {
    vesselName: '',
    vessel_ID: '',
    vessel_Object_ID: '',
  },

  setVessel: (args) =>
    set({
      Vessel: args,
    }),
}))

export default useSelectedVesselStoreLanding
