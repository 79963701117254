import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Grid as KGrid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Card, MenuItem, Paper, useTheme } from '@mui/material';
import CustomNoRowsOverlay from 'generic-component/CustomNoRowsOverlay';
import useEquipmentUtilizationConfigStore from 'store/EquipmentUtilizationConfigurationStore';
import * as yup from 'yup';
import useEquipUtilGridValidation from './EquipmentUtilGridValidation';
import { Error } from '@progress/kendo-react-labels';

const schema = yup.object().shape({
  minimumOperatingHours: yup.number().integer('Decimal values are not accepted').min(0, 'Minimum value is 0').max(2000, 'Maximum value is 2000').typeError('Required').required('Required'),
});

const NumberCell = (props) => {
  const gridError = useEquipUtilGridValidation((state) => state.GridError);
  const setGridError = useEquipUtilGridValidation((state) => state.setGridError);
  const clearGridError = useEquipUtilGridValidation((state) => state.clearGridError);
  const dataValue = props.dataItem[props.field];
  const formattedValue = typeof dataValue === 'number' ? Number(dataValue).toLocaleString('en-US') : dataValue;

  try {
    schema.validateSync(props.dataItem, { abortEarly: false });

    if (gridError[`${props.dataItem.tableIndex}_${props.field}`]) {
      clearGridError(`${props.dataItem.tableIndex}_${props.field}`);
    }
  } catch (err) {
    let fieldHasError = false;
    err.inner.map((i) => {
      if (i.path === props.field) {
        fieldHasError = true;
      }
      if (i.path === props.field && !gridError[`${err.value.tableIndex}_${i.path}`]) {
        setGridError({
          key: `${err.value.tableIndex}_${i.path}`,
          value: { type: 'custom', message: i.message },
        });
      }
    });
    if (!fieldHasError && gridError[`${props.dataItem.tableIndex}_${props.field}`]) {
      clearGridError(`${props.dataItem.tableIndex}_${props.field}`);
    }
  }

  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value,
    });
  };

  if (!props.dataItem.inEdit) {
    return (
      <td style={{ textAlign: 'right' }}>
        {dataValue === null ? '' : formattedValue}
        {/* {Boolean(gridError[`${props.dataItem.tableIndex}_${props.field}`]) && <Error>{gridError[`${props.dataItem.tableIndex}_${props.field}`]?.message}</Error>} */}
      </td>
    );
  }
  return (
    <td>
      <NumericTextBox onChange={handleChange} value={dataValue} spinners={false} format="n2" />
      {Boolean(gridError[`${props.dataItem.tableIndex}_${props.field}`]) && <Error>{gridError[`${props.dataItem.tableIndex}_${props.field}`]?.message}</Error>}
    </td>
  );
};

export default function EquipUtilGrid({ isDisabled, Operations, equipmentData, handleApplyToAll }) {
  const [editID, setEditID] = useState(null);
  const data = useEquipmentUtilizationConfigStore((state) => state.EquipmentData);
  const setData = useEquipmentUtilizationConfigStore((state) => state.updateEquipment);
  const gridRef = useRef(null);
  const theme = useTheme();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data && data[0]?.minimumOperatingHours && !isDisabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [data, isDisabled]);

  let uniqueArr = [];
  let VesselInfo = [];
  if (data) {
    VesselInfo = data;
    VesselInfo.forEach((element, index) => {
      uniqueArr.push({
        ...element,
        tableIndex: index + 1,
      });
    });
  }
  const rows = uniqueArr ?? [];
  const rowClick = (event) => {
    setEditID(event.dataItem.tableIndex);
  };
  const itemChange = (event) => {
    const inEditID = event.dataItem.tableIndex;
    const field = event.field || '';
    const newData = rows.map((item) =>
      item.tableIndex === inEditID
        ? {
            ...item,
            [field]: event.value,
          }
        : item
    );
    setData(newData);
  };

  const applyToAll = () => {
    if (data.length > 0) {
      const minHours = data[0].minimumOperatingHours;
      const updatedData = data.map((item) => ({
        ...item,
        minimumOperatingHours: minHours,
      }));
      handleApplyToAll(updatedData);
    }
  };

  return (
    <Grid container item xs={12} sm={12} md={12} sx={{ pt: '10px' }}>
      <Grid item xs={12}>
        <Typography display="inline-block" variant="h4" sx={{ fontSize: '15px', fontWeight: 450 }}>
          EQUIPMENT WISE UTILIZATION HOURS
        </Typography>
      </Grid>

      <Grid item md={5.5}>
        <Grid item xs={12} textAlign={'right'}>
          <Button
            disabled={disabled}
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              applyToAll();
            }}
            sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '' }}>
            Apply To All
          </Button>
        </Grid>
        <Paper sx={{ width: '100%', pt: '8px', width: '100%' }}>
          <div ref={gridRef}>
            <KGrid
              style={{
                height: '76vh',
                borderColor: theme.palette.mode === 'light' ? 'rgba(196, 196, 196, 0.4)' : 'rgba(120, 145, 156, 0.2)',
              }}
              data={rows?.map((item) => ({
                ...item,
                inEdit: isDisabled ? false : true,
              }))}
              total={rows.length}
              pageable={false}
              editField="inEdit"
              onRowClick={rowClick}
              onItemChange={itemChange}
              selectedField="selected"
              selectable="multiple">
              rowHeight={25}
              <GridNoRecords>
                <CustomNoRowsOverlay />
              </GridNoRecords>
              <GridColumn field="tableIndex" title="Id" width="0px" editable={false} />
              <GridColumn field="equipmentMasterName" title={<span style={{}}>Equipment Name</span>} editable={false} width="290px" />
              <GridColumn
                field="minimumOperatingHours"
                title={<span style={{}}>Running Hours(HH)*</span>}
                cell={NumberCell}
                width="149px"
                headerCell={(props) => <div style={{ textAlign: 'right' }}>{props.title}</div>}
              />
            </KGrid>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
