import { Grid, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';

export default function ConfirmCopy({ selectedVessel, SelectedVesselsData, handleCloseConfirmCopy, handleCopyConfirm }) {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      title={
        <div>
          <Typography variant="h3" sx={{ paddingRight: '16px' }}>
            Equipments Not Found!
          </Typography>
        </div>
      }
      //   open={openCopyTo}
      onClose={handleCloseConfirmCopy}
      width={matchesXs ? '100%' : '50%'}
      height={625}
      style={{ fontWeight: 500, zIndex: 2, marginTop: 25 }}
      sx={{
        '& .MuiPaper-root': {
          padding: 0,
          maxWidth: '50%',
        },
        '& .k-dialog .k-i-close': {
          color: 'red',
        },
      }}>
      <Grid container item md={12}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography display="inline-block" variant="h4">
              The following vessel's listed equipment are not matching {selectedVessel.name} vessel:
            </Typography>
          </Grid>

          <Grid py={'12px'}>
            {SelectedVesselsData.map((item, index) => (
              <Grid container item sm={12} key={index} mb={'6px'}>
                <Grid item xs={12}>
                  <Typography display={'inline-block'} variant="body2">
                    {index + 1}. In {item.vesselName} Vessel
                  </Typography>
                  {item.additionalEquipments?.map((data) => (
                    <Typography variant="body2" ml={'24px'}>
                      {data}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Typography display="inline-block" variant="h4" sx={{ color: theme.palette.mode === 'light' ? '#f44336' : '#D32F2F' }}>
              Do you still want to proceed?
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <DialogActionsBar>
        <Grid container justifyContent="flex-end">
          <Button
            startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faCircleCheck} />}
            variant="contained"
            size="small"
            color="primary"
            sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '', marginRight: '8px' }}
            onClick={handleCopyConfirm}>
            Yes
          </Button>
          <Button
            startIcon={<FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faXmark} />}
            variant="outlined"
            size="small"
            color="primary"
            sx={{ color: theme.palette.mode === 'light' ? 'primary' : '' }}
            onClick={handleCloseConfirmCopy}>
            Cancel
          </Button>
        </Grid>
      </DialogActionsBar>
    </Dialog>
  );
}
