import React, { useMemo, useEffect, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar } from '@mui/material';

// project imports
import Header from '../generic-layout-componant/Header';
import Sidebar from '../MainLayout/Sidebar';
import useConfig from 'hooks/useConfig';
import { useSelector } from 'store';
import { SWRConfig } from 'swr';
import api from '../../utils/api';
// import Customization from '../Customization'
import useSelectedVesselStore from '../../store/vesselStore';
import LoaderforVesseldata from '../../generic-component/LoaderforVesseldata';
import axiosServices, { axiosInstance } from 'utils/axios';
import { SupportPage } from 'SSPL_Support';
import { ServicePage } from 'SSPL_Support';

// styles
const Main = styled('main')(({ theme }) => ({
  ...theme.typography.mainContent,
  ...{
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: `100%`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: '100%',
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5px',
      width: `100%`,
      padding: '0px',
      marginRight: '5px',
    },
  },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { container } = useConfig();
  const fetchVesselsList = useSelectedVesselStore((state) => state.fetch);
  const user = JSON.parse(localStorage.getItem('auth'));
  const email = user?.user?.email;
  useEffect(() => {
    fetchVesselsList();
  }, [fetchVesselsList]);

  const header = useMemo(
    () => (
      <Toolbar sx={{ boxShadow: '0 4px 20px rgb(0 0 0 / 40%)' }}>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          '& .MuiToolbar-root': {
            minHeight: '46px !important',
          },
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
          background: theme.palette.primaryLight,
        }}>
        {header}
      </AppBar>
      <Sidebar />

      {/* main content */}
      <Main
        sx={{
          mt: 5.375,
          mr: 0,
          p: 0,
          minHeight: `calc(100vh - 44px)`,
          backgroundColor: theme.palette.mode == 'dark' && 'rgb(6, 13, 18)',
        }}
        theme={theme}
        open={drawerOpen}>
        <LoaderforVesseldata>
          {/* Global SWR config for requests throughout application */}
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              refreshInterval: 0,
              shouldRetryOnError: false,
              fetcher: async (args) => {
                const response = await api(window.Constants.BaseUrl + args).json();
                return response.data;
              },
            }}>
            {container && (
              <Container maxWidth="lg">
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
              </Container>
            )}
            {!container && (
              <>
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
              </>
            )}
          </SWRConfig>
        </LoaderforVesseldata>
        <SupportPage userEmail={email} appName="Wheelhouse" axiosInstance={axiosInstance} apiUrl={`${window.Constants.BaseUrl}/support/raiseServiceRequest`} />
      </Main>
      {/* <Customization /> */}
    </Box>
  );
};

export default MainLayout;
