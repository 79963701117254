import { Box, Card, Grid, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import { GridColumn as Column, GridNoRecords, getSelectedState } from '@progress/kendo-react-grid';
import CustomNoRowsOverlay from 'generic-component/CustomNoRowsOverlay';
import KDataGrid from 'generic-component/KDataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { ColumnMenu } from './columnMenu';
import { getter } from '@progress/kendo-react-common';
import { process } from '@progress/kendo-data-query';
import { Checkbox } from '@progress/kendo-react-inputs';
import useEquipmentUtilizationConfigStore from 'store/EquipmentUtilizationConfigurationStore';

const styles = `
  .kendo-light-theme .k-grid td.k-selected, .kendo-light-theme .k-grid .k-table-row.k-selected > td, .kendo-light-theme .k-grid .k-table-td.k-selected, .kendo-light-theme .k-grid .k-table-row.k-selected > .k-table-td {
    background-color: #EDF0F0 ;
}
.kendo-dark-theme .k-grid td.k-selected, .kendo-dark-theme .k-grid .k-table-row.k-selected > td, .kendo-dark-theme .k-grid .k-table-td.k-selected, .kendo-dark-theme .k-grid .k-table-row.k-selected > .k-table-td {
    background-color: #243640;
}
.kendo-dark-theme select.k-picker-md {
    padding-block: 4px;
    padding-inline: 8px;
     background-color: #14252f;
     color:#ffffff;
}
.kendo-light-theme .k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-block: 8px;
    padding-inline: 12px;
    align-items: start;
    justify-content: start;
}
.kendo-dark-theme .k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-block: 8px;
    padding-inline: 12px;
    align-items: start;
    justify-content: start;
}
.kendo-dark-theme .k-grid-md td, .kendo-dark-theme .k-grid-md .k-table-td {
    padding-block: 8px;
    padding-inline: 12px;
    text-align: left;
}
.kendo-light-theme .k-grid-md td, .kendo-light-theme .k-grid-md .k-table-td {
    padding-block: 8px;
    padding-inline: 12px;
    text-align: left;
}
`;

const DATA_ITEM_KEY = 'tableIndex';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

export default function CopyToVesselGrid({ CopyToVesselData, isDisabled, disabled }) {
  const theme = useTheme();
  const [filteredRows, setFilteredRows] = useState(CopyToVesselData || []);
  const [selectedVessel, setSelectedVessel] = useState(null);

  const SelectedVessels = useEquipmentUtilizationConfigStore((state) => state.SelectedVessels);
  const setSelectedVessels = useEquipmentUtilizationConfigStore((state) => state.updateSelectedVessels);

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedIds, setSelectedIds] = useState([]);

  const isSelected = (dataItem) => {
    return selectedIds.includes(dataItem.tableIndex);
  };

  const filterGridData = useCallback(
    (searchTerm) => {
      if (CopyToVesselData) {
        if (!searchTerm) {
          setFilteredRows(CopyToVesselData);
        }
      }
    },
    [CopyToVesselData]
  );

  useEffect(() => {
    filterGridData(selectedVessel);
  }, [filterGridData, selectedVessel]);

  let uniqueArr = [];
  let VesselInfo = [];
  if (CopyToVesselData) {
    VesselInfo = CopyToVesselData;
    VesselInfo.forEach((element, index) => {
      uniqueArr.push({
        ...element,
        VesselName: `${element.vesselName}`,
        VesselIMO: `${element.imo}`,
        VesselType: `${element.vesselType}`,
        tableIndex: index,
      });
    });
  }

  const rows = uniqueArr ?? uniqueArr;

  const createDataState = (dataState) => {
    return {
      result: process(rows.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 12,
    skip: 0,
  });

  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [pageSizeValue, setPageSizeValue] = React.useState();
  const dataStateChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === 'All' ? rows.length : event.dataState.take;
    setResult(
      process(rows, {
        ...event.dataState,
        take,
      })
    );
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setDataState({
      ...event.dataState,
      take,
    });
  };

  useEffect(() => {
    const processedData = process(rows, dataState);
    setResult(processedData);
  }, [CopyToVesselData]);

  const [selectedState, setSelectedState] = React.useState({});

  const handleSelection = (id) => {
    const vesselIds = id?.map((item) => {
      return CopyToVesselData[item]?.vesselId;
    });

    setSelectedVessels(vesselIds);
  };
  console.log(SelectedVessels);

  return (
    <Grid container item>
      <Card
        sx={{
          width: '100%',
          position: 'relative',
        }}>
        <style>{styles}</style>
        <KDataGrid
          data={result.data?.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          style={{ display: 'flex', flexDirection: 'column' }}
          {...dataState}
          onDataStateChange={dataStateChange}
          total={rows?.length}
          pageable={{
            buttonCount: 2,
            pageSizes: [15, 30, 60, 90, 'All'],
            pageSizeValue: pageSizeValue,
            responsive: false,
          }}
          editField="inEdit"
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            mode: 'single',
            cell: false,
            drag: false,
          }}
          navigatable={true}>
          <GridNoRecords>
            <CustomNoRowsOverlay />
          </GridNoRecords>
          <Column
            field=""
            title=""
            width={matchesXs ? '8px' : '45px'}
            className="k-checkbox"
            cell={(props) => {
              const isChecked = isSelected(props?.dataItem);

              return (
                <td>
                  <Checkbox
                    checked={isChecked}
                    style={{
                      backgroundColor: isChecked ? (theme.palette.mode === 'light' ? '#173e43' : '#78919C') : '',
                      borderColor: theme.palette.mode === 'light' ? '#173e43' : '#78919C',
                      width: '15px',
                      height: '15px',
                      boxShadow: '0 0 0 0',
                      marginTop: '-1.8px',
                    }}
                    onChange={() => {
                      const clickedId = props?.dataItem?.tableIndex;
                      const updatedIds = isChecked ? selectedIds?.filter((id) => id !== clickedId) : [...selectedIds, clickedId];
                      setSelectedIds(updatedIds);
                      handleSelection(updatedIds);
                    }}
                  />
                </td>
              );
            }}
          />
          <Column field="VesselIMO" title="IMO" width="auto" editable={false} cell={(props) => <td style={{ textAlign: 'left' }}>{props.dataItem[props.field]}</td>} columnMenu={ColumnMenu} />
          <Column
            field="VesselName"
            title="Vessel Name"
            width="auto"
            editable={false}
            filter="text"
            cell={(props) => <td style={{ textAlign: 'left' }}>{props.dataItem[props.field]}</td>}
            columnMenu={ColumnMenu}
          />
          <Column
            field="VesselType"
            title="Vessel Type"
            width="auto"
            editable={false}
            cell={(props) => <td style={{ textAlign: 'left' }}>{props.dataItem[props.field]}</td>}
            filter="text"
            columnMenu={ColumnMenu}
          />
        </KDataGrid>
      </Card>
    </Grid>
  );
}
