// material-ui
import { useTheme } from '@mui/styles';
import { Avatar, Box, Button, Grid, Tooltip, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection'
// import MobileSection from './MobileSection'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
// import { useCallback } from 'react'
import { openDrawer } from 'store/slices/menu';
///import useSelectedVesselStore from '../../../store/vesselStore'
//import AsyncSelect from '../../../generic-component/AsyncSelect'
// import AutocompleteLanding from '../../../generic-component/AutocompleteLanding'
import { authService } from '../../../services/auth-service';
import { IconMenu2 } from '@tabler/icons';
import ThemeSection from '../ThemeSection';
import useAlertStore from 'store/alertStore';
import SuccessAlert from 'generic-component/FSuccessAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faMapLocation } from '@fortawesome/pro-solid-svg-icons';
import useMapViewStore from 'store/MapViewStore';
import LocalizationSection from '../LocalizationSection';
import Popper from '@mui/material/Popper';
import ChangePasswordPopup from './ChangePasswordPopup';
import CloseIcon from '@mui/icons-material/Close';
import SupportButton from '../SupportSection';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const HeaderWrapper = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const { drawerOpen } = useSelector((state) => state.menu);
  const alertOpen = useAlertStore((state) => state.open);
  const alertMessage = useAlertStore((state) => state.message);
  const setAlertOpen = useAlertStore((state) => state.setAlertOpen);
  let user = JSON.parse(localStorage.getItem('auth'));
  let firstName = user?.user?.firstName ?? 'No';
  let lastName = user?.user?.lastName ?? 'Name';
  let flName = Array.from(firstName)[0] + Array.from(lastName)[0];
  const [changePasswordFields, setChangePasswordFields] = useState(false);
  const updateView = useMapViewStore((state) => state.update);
  const setView = useMapViewStore((state) => state.defaultView);
  const avatarRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleAvatarClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  function mapViewToggle() {
    updateView('MapView');
  }
  function otherViewToggle() {
    updateView('OtherView');
  }
  const color = theme.palette.mode === 'dark' ? theme.palette.common.white : '#14252f';
  const lightButton = {
    borderRadius: 0,
    fontSize: 13.5,
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : '#83d3d0',
    border: '0.1px solid',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary[800] : '#e9dede',

    '&:hover': { background: theme.palette.mode === 'dark' ? theme.palette.secondary[800] : theme.palette.secondary[800] },
    color: color,
    height: 35,
  };
  const darkButton = {
    borderRadius: 0,
    background: theme.palette.mode === 'dark' ? '#14252f' : 'white',
    color: color,
    fontSize: 13.5,
    border: '0.1px solid',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary[800] : '#e9dede',
    height: 35,

    '&:hover': { background: theme.palette.mode === 'dark' ? '#14252f' : 'white' },
  };
  const ButtonFunction = (pathName) => {
    if (pathName.pathName == '/') {
      return (
        <Grid container columnSpacing={1}>
          <Grid item sx={{ textAlign: 'center' }} xs={12} lg={12}>
            <Button
              variant="contained"
              style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, height: 35 }}
              sx={setView === 'MapView' ? lightButton : darkButton}
              onClick={() => mapViewToggle()}>
              <FontAwesomeIcon icon={faMapLocation} style={{ marginRight: 8, marginBottom: 3 }} />
              Map View
            </Button>
            <Button
              variant="contained"
              style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, height: 35 }}
              sx={setView === 'OtherView' ? lightButton : darkButton}
              onClick={() => otherViewToggle()}>
              <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 8, marginBottom: 3 }} />
              360° View
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return '';
    }
  };

  // const updateSelectedVessel = useSelectedVesselStore((state) => state.update)
  // const updateSelectedVesselLanding = useSelectedVesselStoreLanding((state) => state.setVessel)
  // const selectedVesselLanding = useSelectedVesselStoreLanding((state) => state.Vessel)
  //  const selectedVessel = useSelectedVesselStore((state) => state.selectedVessel)
  // function DropDownOrSearchRender({ pathname }) {
  //   if (pathname === '/' || pathname === '/landing') {
  //     return <AutocompleteLanding defaultVessel={SelectedVesselLanding} handleChangeLanding={handleVesselSelectionChangeLanding} />
  //   } else {
  //     return <AsyncSelect defaultVessel={selectedVessel} handleChange={handleVesselSelectionChange} />
  //   }
  // }

  // function DropDownOrSearchRender({ pathname }) {
  //   if (pathname === '/' || pathname === '/landing') {
  //     return <AutocompleteLanding defaultVessel={SelectedVesselLanding} handleChangeLanding={handleVesselSelectionChangeLanding} />
  //   } else {
  //     return <AsyncSelect defaultVessel={selectedVessel} handleChange={handleVesselSelectionChange} />
  //   }
  // }

  // const handleVesselSelectionChange = useCallback(
  //   (value) => {
  //     updateSelectedVessel(value)
  //   },
  //   [updateSelectedVessel]
  // )

  // const handleVesselSelectionChangeLanding = useCallback(
  //   (value) => {
  //     updateSelectedVesselLanding(value)
  //   },
  //   [updateSelectedVesselLanding]
  // )
  const LogotitleColor = theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900];

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('lg')]: {
            width: 'auto',
          },
        }}>
        <Box component="span" sx={{ display: { xs: 'none', md: 'contents' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>

        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            marginLeft: { xs: 0, md: '42px' },
            marginTop: { xs: 0.25, md: 0.625 },
            height: 35,
            width: 35,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
              color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
            },
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit">
          <IconMenu2 stroke={1.5} size="1.3rem" />
        </Avatar>
        <Box sx={{ flexGrow: 1, marginLeft: 3 }} />
        <Box
          style={{
            color: LogotitleColor,
            fontFamily: 'sans-serif',
            fontSize: '17.5px',
            marginTop: 12,
            letterSpacing: 0.02,
            fontWeight: 700,
            wordSpacing: 15,
          }}>
          {/* <DropDownOrSearchRender pathname={location.pathname} /> */}
          {/* <LandingPageCompanyNameSection /> */}
        </Box>
      </Box>
      {/* <ButtonFunction pathName={location.pathname} /> */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <SuccessAlert open={alertOpen} setOpen={setAlertOpen} message={alertMessage} />
      <SupportButton />
      {window.Constants.IsMutliLangFeature && (
        <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 1 }}>
          <LocalizationSection />
        </Box>
      )}
      <ThemeSection />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Tooltip title={'Logout   ' + firstName + ' ' + lastName} placement="bottom-start">
        <Avatar
          ref={avatarRef}
          sx={{
            width: 34,
            height: 34,
            fontSize: '1rem',
            cursor: 'pointer',
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
          }}
          onClick={handleAvatarClick}>
          {flName}
        </Avatar>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-end" sx={{ zIndex: 1000 }}>
        <Box
          sx={{
            mt: '12px',
            pt: '12px',
            pb: '12px',
            pr: '12px',
            pl: '12px',
            minWidth: 380,
            minHeight: changePasswordFields ? 380 : 170,
            backgroundColor: theme.palette.mode === 'dark' ? '#0E1B23' : '#FFFFFF',
            borderRadius: 4,
            boxShadow: '10',
          }}>
          <IconButton
            onClick={() => {
              setAnchorEl(null);
              setChangePasswordFields(false);
            }}
            sx={{
              position: 'absolute',
              top: 20,
              right: 3,
              color: 'grey',
            }}>
            <CloseIcon />
          </IconButton>
          <ChangePasswordPopup changePasswordFields={changePasswordFields} setChangePasswordFields={setChangePasswordFields} />
        </Box>
      </Popper>
    </>
  );
};

export default HeaderWrapper;
