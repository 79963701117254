// TODO: Convert to TS

import ky from 'ky';
import { authHeader } from '../../src/services/auth-header';
import { authService } from '../../src/services/auth-service';

export default ky.extend({
  retry: 0,
  timeout: false,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', authHeader().Authorization);
      },
    ],

    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401 || response.status === 403) {
          // Get new token and refresh token upon encountering 401 error
          authService.logout();
          //  return ky(request); ///will make the request again
        }
      },
    ],
  },
});
